define("ui/components/container/form-upgrade-replication-controller/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PQXF2u0m",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"box\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[1,[30,[36,2],[\"formUpgrade.strategy.minReadySeconds.label\"],null]],[13],[2,\"\\n\"],[6,[37,4],null,[[\"editable\",\"value\"],[[35,3],[35,0,[\"minReadySeconds\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"input-group\"],[12],[2,\"\\n          \"],[1,[30,[36,1],null,[[\"classNames\",\"value\"],[\"form-control\",[35,0,[\"minReadySeconds\"]]]]]],[2,\"\\n          \"],[10,\"span\"],[14,0,\"input-group-addon bg-default\"],[12],[1,[30,[36,2],[\"generic.seconds\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"help-block\"],[12],[1,[30,[36,2],[\"formUpgrade.strategy.minReadySeconds.detail\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"workloadConfig\",\"input\",\"t\",\"editing\",\"input-or-display\"]}",
    "meta": {
      "moduleName": "ui/components/container/form-upgrade-replication-controller/template.hbs"
    }
  });

  _exports.default = _default;
});