define("ui/models/kontainerdriver", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var KontainerDriver = _resource.default.extend({
    intl: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    type: 'kontainerDriver',
    availableActions: Ember.computed('actionLinks.{activate,deactivate}', function () {
      var a = Ember.get(this, 'actionLinks') || {};
      return [{
        label: 'action.activate',
        icon: 'icon icon-play',
        action: 'activate',
        enabled: !!a.activate,
        bulkable: true
      }, {
        label: 'action.deactivate',
        icon: 'icon icon-pause',
        action: 'promotDeactivate',
        enabled: !!a.deactivate,
        bulkable: true,
        altAction: 'deactivate'
      }];
    }),
    displayName: Ember.computed('id', 'intl.locale', 'name', function () {
      var intl = Ember.get(this, 'intl');
      var name = Ember.get(this, 'name');
      var keyByName = "kontainerDriver.displayName.".concat(name);
      var keyById = "kontainerDriver.displayName.".concat(Ember.get(this, 'id'));

      if (name && intl.exists(keyByName)) {
        return intl.t(keyByName);
      }

      if (intl.exists(keyById)) {
        return intl.t(keyById);
      } else if (name) {
        return name.capitalize();
      } else {
        return "(".concat(Ember.get(this, 'id'), ")");
      }
    }),
    canEdit: Ember.computed('links.update', 'builtin', function () {
      return !!Ember.get(this, 'links.update') && !Ember.get(this, 'builtin');
    }),
    hasUi: Ember.computed('hasBuiltinUi', 'uiUrl', function () {
      return !!Ember.get(this, 'uiUrl');
    }),
    actions: {
      activate: function activate() {
        return this.doAction('activate');
      },
      deactivate: function deactivate() {
        return this.doAction('deactivate');
      },
      edit: function edit() {
        Ember.get(this, 'modalService').toggleModal('modal-edit-driver', this);
      },
      promotDeactivate: function promotDeactivate() {
        Ember.get(this, 'modalService').toggleModal('modal-confirm-deactivate', {
          originalModel: this,
          action: 'deactivate'
        });
      }
    }
  });

  var _default = KontainerDriver;
  _exports.default = _default;
});