define("ui/components/modal-restore-backup/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-restore-backup/template", "moment"], function (_exports, _modalBase, _template, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal'],
    backupId: null,
    restoreRkeConfig: null,
    loadingBackups: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.initOwnProperties();
      this.fetchAllBackupsForCluster();
    },
    actions: {
      restore: function restore() {
        var _this = this;

        var backupId = this.backupId,
            restoreRkeConfig = this.restoreRkeConfig;
        var out = {};

        if (backupId) {
          Ember.set(out, 'etcdBackupId', backupId);

          if (restoreRkeConfig !== 'etcd') {
            Ember.set(out, 'restoreRkeConfig', restoreRkeConfig);
          }

          this.modalOpts.cluster.doAction('restoreFromEtcdBackup', out).then(function () {
            _this.send('cancel');
          });
        } else {
          this.growl.fromError(this.intl.t('modalRestoreBackup.error'));
        }
      }
    },
    updateRestoreRkeConfig: Ember.observer('backupId', function () {
      var value = Ember.get(this, 'backupId') ? 'etcd' : '';
      Ember.set(this, 'restoreRkeConfig', value);
    }),
    availableBackups: Ember.computed('modalOpts.cluster.etcdbackups.[]', function () {
      return Ember.get(this, 'modalOpts.cluster.etcdbackups').map(function (backup) {
        var time = (0, _moment.default)(Ember.get(backup, 'created'));
        var hyphenatedVersion = backup.status.kubernetesVersion ? " - ".concat(backup.status.kubernetesVersion) : '';
        return {
          id: backup.id,
          label: "".concat(backup.displayName, " (").concat(time.format('MMMM Do YYYY, H:mm:ss'), ")").concat(hyphenatedVersion),
          created: backup.created,
          state: backup.state
        };
      }).sortBy('created').reverse();
    }),
    selectedBackup: Ember.computed('modalOpts.cluster.etcdbackups.[]', 'backupId', function () {
      var backupId = Ember.get(this, 'backupId');
      return !backupId ? null : Ember.get(this, 'modalOpts.cluster.etcdbackups').findBy('id', backupId);
    }),
    selectedVersion: Ember.computed('selectedBackup.status.kubernetesVersion', function () {
      return Ember.get(this, 'selectedBackup.status.kubernetesVersion') || this.intl.t('modalRestoreBackup.type.versionUnknown');
    }),
    k8sVersionDisabled: Ember.computed('selectedVersion', 'restorationTypeDisabled', function () {
      return !Ember.get(this, 'restorationTypeDisabled') && Ember.get(this, 'selectedVersion') === this.intl.t('modalRestoreBackup.type.versionUnknown');
    }),
    k8sVersionRadioDisabled: Ember.computed('k8sVersionDisabled', 'restorationTypeDisabled', function () {
      return Ember.get(this, 'k8sVersionDisabled') || Ember.get(this, 'restorationTypeDisabled');
    }),
    restorationTypeDisabled: Ember.computed('selectedBackup', function () {
      return !Ember.get(this, 'selectedBackup');
    }),
    initOwnProperties: function initOwnProperties() {
      var backupId = this.modalOpts.selection ? this.modalOpts.selection.id : '';
      var restoreRkeConfig = backupId ? 'etcd' : '';
      Ember.setProperties(this, {
        backupId: backupId,
        restoreRkeConfig: restoreRkeConfig,
        errors: []
      });
    },
    fetchAllBackupsForCluster: function fetchAllBackupsForCluster() {
      var _this2 = this;

      Ember.set(this, 'loadingBackups', true);
      this.modalOpts.cluster.store.findAll('etcdbackup').finally(function () {
        Ember.set(_this2, 'loadingBackups', false);
      });
    }
  });

  _exports.default = _default;
});