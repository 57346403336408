define("ui/components/form-node-selector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2S7r4ouk",
    "block": "{\"symbols\":[],\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"box mb-10 pt-5\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"btn bg-transparent text-small vertical-middle\"],[24,4,\"button\"],[4,[38,1],[[32,0],\"removeRule\",[35,0]],null],[12],[2,\"\\n            \"],[1,[30,[36,2],[\"formNodeAffinity.removeRuleLabel\"],null]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[3,\" 28px is the height of the button, no jumpy \"],[2,\"\\n        \"],[10,\"div\"],[14,5,\"height: 28px;\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"term\",\"editing\"],[[35,0],[35,3]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"rule\",\"action\",\"t\",\"editing\",\"if\",\"form-node-requirement\"]}",
    "meta": {
      "moduleName": "ui/components/form-node-selector/template.hbs"
    }
  });

  _exports.default = _default;
});