define("ui/authenticated/cluster/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/K36cZ6r",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"clustersPage.editClusterName\"],[[\"name\"],[[35,0,[\"originalCluster\",\"displayName\"]]]]]],[2,\" (\"],[1,[35,0,[\"originalCluster\",\"displayProvider\"]]],[2,\")\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"cru-cluster\",[],[[\"@applyClusterTemplate\",\"@clusterTemplateRevisionId\",\"@model\",\"@initialProvider\",\"@provider\",\"@mode\",\"@close\"],[[30,[36,4],[[30,[36,3],[[35,2]],null]],null],[30,[36,5],[[35,2]],null],[34,0],[34,6,[\"clusterProvider\"]],[34,7],\"edit\",[30,[36,8],[[32,0],\"close\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"t\",\"clusterTemplateRevision\",\"is-empty\",\"not\",\"mut\",\"cluster\",\"provider\",\"action\"]}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/edit/template.hbs"
    }
  });

  _exports.default = _default;
});