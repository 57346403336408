define("ui/components/modal-feedback/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "etmdv/U+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"pt-10 pb-40 pl-40 pr-40 welcome-copy\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"header\"],[12],[1,[30,[36,1],[\"modalFeedback.header\"],[[\"appName\"],[[35,0,[\"appName\"]]]]]],[13],[2,\"\\n  \"],[10,\"p\"],[12],[1,[30,[36,1],[\"modalFeedback.subtitle\"],null]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,1,\"feedback-form\"],[14,0,\"clearfix\"],[14,5,\"min-height: 100px;\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-center p-20\"],[12],[10,\"i\"],[14,0,\"icon icon-spinner icon-spin\"],[12],[13],[2,\" \"],[1,[30,[36,1],[\"generic.loading\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"footer-actions pt-20\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bg-primary\"],[16,\"disabled\",[34,2]],[24,4,\"button\"],[4,[38,3],[[32,0],\"submit\"],null],[12],[1,[30,[36,1],[\"modalFeedback.send\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"settings\",\"t\",\"loading\",\"action\"]}",
    "meta": {
      "moduleName": "ui/components/modal-feedback/template.hbs"
    }
  });

  _exports.default = _default;
});