define("ui/components/modal-delete-group-role-bindings/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sFPetbNm",
    "block": "{\"symbols\":[\"grb\",\"idx\"],\"statements\":[[10,\"div\"],[14,0,\"container-header-text\"],[12],[2,\"\\n  \"],[10,\"h3\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"modalDeleteGroupRoleBinding.header\"],[[\"groupName\"],[[35,0,[\"modalOpts\",\"model\",\"groupPrincipalName\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"horizontal-form container-fluid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-12 text-center\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[12],[2,\"\\n          \"],[1,[32,1,[\"groupRoleBindingName\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n    \"],[8,\"save-cancel\",[],[[\"@createLabel\",\"@save\",\"@cancel\"],[\"modalDeleteGroupRoleBinding.createLabel\",[30,[36,5],[[32,0],\"save\"],null],[30,[36,5],[[32,0],\"cancel\"],null]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"text-center protip mt-10\"],[12],[2,\"\\n  \"],[1,[30,[36,1],[\"confirmDelete.protip\"],[[\"key\"],[[35,6]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"modalService\",\"t\",\"mappedGroupRoleBindingNamesIds\",\"-track-array\",\"each\",\"action\",\"alternateLabel\"]}",
    "meta": {
      "moduleName": "ui/components/modal-delete-group-role-bindings/template.hbs"
    }
  });

  _exports.default = _default;
});