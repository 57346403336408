define("ui/components/notifier/notifier-table/component", ["exports", "ui/components/notifier/notifier-table/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var headers = [{
    translationKey: 'generic.state',
    name: 'state',
    searchField: 'state',
    sort: ['state', 'name'],
    width: '120'
  }, {
    translationKey: 'generic.name',
    name: 'name',
    searchField: 'name',
    sort: ['name', 'id']
  }, {
    translationKey: 'generic.type',
    name: 'notifierType',
    sort: ['notifierType', 'name'],
    searchField: ['notifierType', 'notifierLabel', 'notifierValue']
  }, {
    classNames: 'text-right pr-20',
    translationKey: 'notifierPage.index.table.created',
    name: 'created',
    searchField: 'displayCreated',
    sort: ['created', 'name']
  }];

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    // input
    model: null,
    sortBy: 'name',
    headers: headers,
    clusterId: Ember.computed.reads('scope.currentCluster.id'),
    filteredNotifiers: Ember.computed('model.@each.clusterId', 'clusterId', function () {
      var data = this.get('model') || [];
      var clusterId = Ember.get(this, 'clusterId');
      return data.filterBy('clusterId', clusterId);
    })
  });

  _exports.default = _default;
});