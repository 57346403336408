define("ui/update-password/route", ["exports", "ui/utils/constants", "shared/utils/parse-version"], function (_exports, _constants, _parseVersion) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    access: Ember.inject.service(),
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    model: function model() {
      var _this = this;

      var promises = [];

      if (Ember.get(this, 'access.firstLogin')) {
        promises.push(Ember.get(this, 'globalStore').find('preference'));
        promises.push(Ember.get(this, 'settings').loadAll());
      }

      return Promise.all(promises).then(function () {
        var cur = Ember.get(_this, "settings.".concat(_constants.default.SETTING.TELEMETRY));
        var version = Ember.get(_this, "settings.".concat(_constants.default.SETTING.VERSION_RANCHER));
        var optIn;

        if (!version || (0, _parseVersion.isDevBuild)(version)) {
          // For dev builds, default to opt out
          optIn = cur === 'in';
        } else {
          // For releases, default to opt in
          optIn = cur !== 'out';
        }

        return {
          user: Ember.get(_this, 'access.me'),
          code: Ember.get(_this, 'access.userCode') || '',
          optIn: optIn
        };
      });
    },
    activate: function activate() {
      $('BODY').addClass('container-farm'); // eslint-disable-line
    },
    deactivate: function deactivate() {
      $('BODY').removeClass('container-farm'); // eslint-disable-line
    }
  });

  _exports.default = _default;
});