define("ui/models/clustertemplate", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var ClusterTemplate = _resource.default.extend({
    globalStore: Ember.inject.service(),
    router: Ember.inject.service(),
    modal: Ember.inject.service(),
    access: Ember.inject.service(),
    revisions: (0, _denormalize.hasMany)('id', 'clustertemplaterevision', 'clusterTemplateId', 'globalStore', null, 'globalStore'),
    type: 'clustertemplate',
    canCloneRevision: true,
    availableActions: Ember.computed('actionLinks.[]', 'canCloneRevision', function () {
      return [{
        label: 'action.revision',
        icon: 'icon icon-copy',
        action: 'newRevision',
        enabled: this.canCloneRevision
      }];
    }),
    revisionsCount: Ember.computed('revisions.[]', function () {
      return isNaN(Ember.get(this, 'revisions.length')) ? 0 : Ember.get(this, 'revisions.length');
    }),
    latestRevision: Ember.computed('revisions.[]', 'revisions.@each.enabled', function () {
      var revisions = (Ember.get(this, 'revisions') || []).filter(function (revision) {
        return revision.enabled;
      });
      return Ember.get(revisions, 'length') === 0 ? null : revisions.sortBy('createdTS').get('lastObject');
    }),
    displayDefaultRevisionId: Ember.computed('defaultRevisionId', 'revisions.[]', 'revisionsCount', function () {
      return Ember.get(this, 'defaultRevisionId').split(':')[1];
    }),
    canEdit: Ember.computed('links.update', function () {
      return !!Ember.get(this, 'links.update');
    }),
    canRemove: Ember.computed('links.remove', function () {
      return !!Ember.get(this, 'links.remove');
    }),
    actions: {
      newRevision: function newRevision() {
        this.router.transitionTo('global-admin.cluster-templates.new-revision', this.id, {
          queryParams: {
            revision: this.defaultRevisionId
          }
        });
      },
      edit: function edit() {
        this.modalService.toggleModal('modal-edit-cluster-template', {
          model: this
        });
      }
    }
  });

  var _default = ClusterTemplate;
  _exports.default = _default;
});