define("ui/components/cru-hpa/component", ["exports", "shared/mixins/view-new-edit", "ui/components/cru-hpa/template", "ui/utils/errors", "shared/mixins/child-hook", "shared/mixins/manage-labels"], function (_exports, _viewNewEdit, _template, _errors, _childHook, _manageLabels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var RESOURCE_METRICS_API_GROUP = 'metrics.k8s.io';

  var _default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    intl: Ember.inject.service(),
    layout: _template.default,
    model: null,
    apiServices: null,
    metrics: Ember.computed.alias('model.metrics'),
    namespace: Ember.computed.alias('model.namespace'),
    didInsertElement: function didInsertElement() {
      if (Ember.get(this, 'metrics.length') === 0) {
        this.send('addMetric');
      }
    },
    actions: {
      addMetric: function addMetric() {
        var metric = Ember.Object.create({
          type: 'Resource',
          name: 'cpu',
          target: {
            type: 'Utilization',
            value: null
          }
        });
        Ember.get(this, 'metrics').pushObject(metric);
      },
      removeMetric: function removeMetric(metric) {
        Ember.get(this, 'metrics').removeObject(metric);
      },
      setLabels: function setLabels(labels) {
        Ember.set(this, 'model.labels', (0, _manageLabels.flattenLabelArrays)(labels));
      }
    },
    namespaceDidChange: Ember.observer('deploymentsChoices', function () {
      var deployments = Ember.get(this, 'deploymentsChoices') || [];
      var found = deployments.findBy('id', Ember.get(this, 'model.workloadId'));

      if (!found) {
        Ember.set(this, 'model.workloadId', null);
      }
    }),
    selectedWorkload: Ember.computed('model.workloadId', 'deployments.[]', function () {
      return (Ember.get(this, 'deployments') || []).findBy('id', Ember.get(this, 'model.workloadId'));
    }),
    deploymentsChoices: Ember.computed('namespace.id', 'deployments.[]', function () {
      var namespaceId = Ember.get(this, 'namespace.id');
      return (Ember.get(this, 'deployments') || []).filter(function (w) {
        return Ember.get(w, 'namespaceId') === namespaceId;
      }).sortBy('displayName');
    }),
    resourceMetricsAvailable: Ember.computed('apiServices', function () {
      var apiServices = Ember.get(this, 'apiServices') || [];
      return apiServices.find(function (api) {
        return Ember.get(api, 'name').split('.').length === 4 && Ember.get(api, 'name').endsWith(RESOURCE_METRICS_API_GROUP);
      });
    }),
    validate: function validate() {
      this._super();

      var intl = Ember.get(this, 'intl');
      var errors = Ember.get(this, 'errors') || [];

      if (Ember.get(this, 'model.minReplicas') === null) {
        errors.pushObject(intl.t('validation.required', {
          key: intl.t('cruHpa.minReplicas.label')
        }));
      }

      (Ember.get(this, 'model.metrics') || []).forEach(function (metric) {
        if (Ember.get(metric, 'target.type') === 'Utilization' && !Ember.get(metric, 'target.utilization')) {
          errors.pushObject(intl.t('validation.required', {
            key: intl.t('cruHpa.metrics.value.label')
          }));
        }

        if (Ember.get(metric, 'target.type') === 'AverageValue' && !Ember.get(metric, 'target.averageValue')) {
          errors.pushObject(intl.t('validation.required', {
            key: intl.t('cruHpa.metrics.value.label')
          }));
        }

        if (Ember.get(metric, 'target.type') === 'Value' && !Ember.get(metric, 'target.value')) {
          errors.pushObject(intl.t('validation.required', {
            key: intl.t('cruHpa.metrics.value.label')
          }));
        }
      });
      Ember.set(this, 'errors', errors.uniq());
      return errors.length === 0;
    },
    willSave: function willSave() {
      var _arguments = arguments,
          _this = this;

      Ember.set(this, 'model.namespaceId', Ember.get(this, 'namespace.id') || '__placeholder__');
      var self = this;
      var sup = this._super;
      var errors = [];
      errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);
      Ember.set(this, 'errors', errors);

      if (Ember.get(errors, 'length') !== 0) {
        return false;
      }

      return this.applyHooks('_beforeSaveHooks').then(function () {
        Ember.set(_this, 'model.namespaceId', Ember.get(_this, 'namespace.id'));
        return sup.apply.apply(sup, [self].concat(_toConsumableArray(_arguments)));
      }).catch(function (err) {
        Ember.set(_this, 'errors', [_errors.default.stringify(err)]);
      });
    }
  });

  _exports.default = _default;
});