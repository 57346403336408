define("ui/components/modal-wechat/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9uTXDUTt",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"isRancher\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"logo-text\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"parachute\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,\"align\",\"center\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[1,[30,[36,2],[\"modalWechat.title\"],null]],[13],[2,\"\\n  \"],[10,\"img\"],[14,\"height\",\"128\"],[14,\"width\",\"128\"],[15,\"src\",[31,[[34,3,[\"baseAssets\"]],\"assets/images/wechat-qr-code.jpg\"]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n\"],[11,\"button\"],[24,0,\"btn btn-primary\"],[24,4,\"button\"],[4,[38,4],[[32,0],\"cancel\"],null],[12],[1,[30,[36,2],[\"generic.closeModal\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"settings\",\"if\",\"t\",\"app\",\"action\"]}",
    "meta": {
      "moduleName": "ui/components/modal-wechat/template.hbs"
    }
  });

  _exports.default = _default;
});