define("ui/components/volume-source/source-host-path/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Xc8K86Op",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[1,[30,[36,0],[\"volumeSource.host-path.path.label\"],null]],[13],[2,\"\\n\"],[6,[37,8],null,[[\"editable\",\"value\"],[[35,7],[35,1,[\"path\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,6],null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[35,1,[\"path\"]],\"form-control\",[30,[36,0],[\"cruPersistentVolume.local.path.placeholder\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[1,[30,[36,0],[\"volumeSource.host-path.kind.label\"],null]],[13],[2,\"\\n\"],[6,[37,3],[[35,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,5],null,[[\"value\",\"content\",\"localizedLabel\"],[[35,1,[\"kind\"]],[35,4],true]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,1,[\"kind\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[[30,[36,2],[\"volumeSource.host-path.kind.\",[35,1,[\"kind\"]]],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"volumeSource.host-path.kind.Any\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"config\",\"concat\",\"if\",\"kindChoices\",\"new-select\",\"input\",\"editing\",\"input-or-display\"]}",
    "meta": {
      "moduleName": "ui/components/volume-source/source-host-path/template.hbs"
    }
  });

  _exports.default = _default;
});