define("ui/components/form-healthcheck/component", ["exports", "ui/components/form-healthcheck/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var NONE = 'none';
  var TCP = 'tcp';
  var HTTP = 'http';
  var HTTPS = 'https';
  var COMMAND = 'command';

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    // Inputs
    healthCheck: null,
    errors: null,
    dnsNote: false,
    successMustBeOne: false,
    isLiveness: false,
    editing: true,
    checkType: null,
    command: null,
    path: null,
    host: null,
    headers: null,
    isNone: Ember.computed.equal('checkType', NONE),
    isTcp: Ember.computed.equal('checkType', TCP),
    isHttp: Ember.computed.equal('checkType', HTTP),
    isHttps: Ember.computed.equal('checkType', HTTPS),
    isHttpish: Ember.computed.or('isHttp', 'isHttps'),
    isCommand: Ember.computed.equal('checkType', COMMAND),
    init: function init() {
      this._super.apply(this, arguments);

      var initial = Ember.get(this, 'initialCheck');
      var check;
      var type = NONE;

      if (initial) {
        check = Object.assign({}, initial);

        if (Ember.get(check, 'tcp')) {
          type = TCP;
        } else if (Ember.get(check, 'command.length')) {
          type = COMMAND;
          Ember.set(this, 'command', Ember.get(check, 'command'));
        } else if (Ember.get(check, 'scheme') === 'HTTP') {
          type = HTTP;
        } else if (Ember.get(check, 'scheme') === 'HTTPS') {
          type = HTTPS;
        }

        if (type === HTTP || type === HTTPS) {
          var originalHeaders = Ember.get(check, 'httpHeaders') || [];
          var host = null;
          var headers = {};
          originalHeaders.forEach(function (h) {
            var name = Ember.get(h, 'name') || '';
            var value = Ember.get(h, 'value') || '';

            if (name.toLowerCase() === 'host') {
              host = value;
            } else {
              Ember.set(headers, name, value);
            }
          });
          Ember.set(this, 'path', Ember.get(check, 'path'));
          Ember.set(this, 'host', host);
          Ember.set(this, 'headers', headers);
        }
      } else {
        check = Ember.get(this, 'store').createRecord({
          type: 'probe',
          failureThreshold: 3,
          initialDelaySeconds: 10,
          path: '/',
          periodSeconds: 2,
          successThreshold: 2,
          timeoutSeconds: 2
        });
      }

      if (Ember.get(this, 'successMustBeOne')) {
        Ember.set(check, 'successThreshold', 1);
      }

      Ember.set(this, 'healthCheck', check);
      Ember.set(this, 'checkType', type);
      this.validate();
      Ember.run.scheduleOnce('afterRender', this, 'checkChanged');
    },
    checkChanged: Ember.observer('path', 'host', 'headers', 'checkType', 'command', function () {
      var check = Ember.get(this, 'healthCheck');

      if (Ember.get(this, 'isNone')) {
        if (this.changed) {
          this.changed(null);
        }

        return;
      }

      Ember.setProperties(check, {
        tcp: Ember.get(this, 'isTcp')
      });

      if (Ember.get(this, 'isHttpish')) {
        var host = Ember.get(this, 'host');
        var httpHeaders = [];

        if (host) {
          httpHeaders.push({
            name: 'Host',
            value: host
          });
        }

        var headers = Ember.get(this, 'headers') || {};
        Object.keys(headers).forEach(function (header) {
          httpHeaders.push({
            name: header,
            value: Ember.get(headers, header)
          });
        });
        Ember.setProperties(check, {
          httpHeaders: httpHeaders,
          path: Ember.get(this, 'path') || '/',
          scheme: Ember.get(this, 'isHttps') ? 'HTTPS' : 'HTTP'
        });
      } else {
        Ember.setProperties(check, {
          path: null,
          httpHeaders: null
        });
      }

      if (Ember.get(this, 'isCommand')) {
        Ember.set(check, 'command', Ember.get(this, 'command'));
      } else {
        Ember.set(check, 'command', null);
      }

      if (this.changed) {
        this.changed(check);
      }
    }),
    validate: Ember.observer('isNone', 'isCommand', 'healthCheck.command.[]', 'healthCheck.port', function () {
      var errors = [];
      Ember.set(this, 'errors', errors);

      if (Ember.get(this, 'isNone')) {
        return;
      }

      if (Ember.get(this, 'isCommand')) {
        if (!Ember.get(this, 'healthCheck.command.length')) {
          errors.push('Health Check command is required');
        }
      } else {
        if (!Ember.get(this, 'healthCheck.port')) {
          errors.push('Health Check port is required');
        }
      }
    })
  });

  _exports.default = _default;
});