define("ui/components/settings/table-rows/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dc/G9Rac",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"tableCountSelect.header\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"box\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"classNames\",\"optionValuePath\",\"optionLabelPath\",\"content\",\"value\"],[\"form-control\",\"value\",\"value\",[35,2],[35,1]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"selectedCount\",\"tableCounts\",\"new-select\"]}",
    "meta": {
      "moduleName": "ui/components/settings/table-rows/template.hbs"
    }
  });

  _exports.default = _default;
});