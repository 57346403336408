define("ui/application/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    settings: Ember.inject.service(),
    resourceActions: Ember.inject.service('resource-actions'),
    tooltipService: Ember.inject.service('tooltip'),
    router: Ember.inject.service(),
    // GitHub auth params
    queryParams: ['isPopup', 'fromAuthProvider'],
    error: null,
    error_description: null,
    state: null,
    code: null,
    isPopup: null,
    tooltip: Ember.computed.oneWay('tooltipService.tooltipOpts.type'),
    tooltipTemplate: Ember.computed.oneWay('tooltipService.tooltipOpts.template'),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('app.environment') === 'development') {
        Ember.run.backburner.DEBUG = true;
      }
    },
    // currentRouteName is set by Ember.Router
    // but getting the application controller to get it is inconvenient sometimes
    currentRouteNameChanged: Ember.observer('router.currentRouteName', function () {
      this.set('app.currentRouteName', this.get('router.currentRouteName'));
    })
  });

  _exports.default = _default;
});