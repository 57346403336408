define("ui/components/certificate-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7vftKjrt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"td\"],[14,0,\"row-check\"],[14,\"valign\",\"middle\"],[14,5,\"padding-top: 2px;\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"nodeId\"],[[35,0,[\"id\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,2,[\"state\"]]]]],[14,0,\"state\"],[12],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,2,[\"name\"]]]]],[14,0,\"force-wrap\"],[12],[2,\"\\n  \"],[6,[37,4],[\"authenticated.project.certificates.detail\",[35,0,[\"id\"]]],null,[[\"default\"],[{\"statements\":[[1,[35,0,[\"displayName\"]]]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,2,[\"cn\"]]]]],[12],[2,\"\\n  \"],[2,[35,0,[\"displayDomainName\"]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,2,[\"expires\"]]]]],[12],[2,\"\\n  \"],[10,\"span\"],[15,0,[31,[[30,[36,5],[[35,0,[\"expiresSoon\"]],\"text-bold text-danger\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,6],[[35,0,[\"expiresDate\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,2,[\"actions\"]]]]],[14,0,\"actions\"],[12],[2,\"\\n  \"],[1,[30,[36,7],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"check-box\",\"dt\",\"badge-state\",\"link-to-external\",\"if\",\"date-from-now\",\"action-menu\"]}",
    "meta": {
      "moduleName": "ui/components/certificate-row/template.hbs"
    }
  });

  _exports.default = _default;
});