define("ui/components/container-dot/component", ["exports", "jquery", "ui/utils/platform", "ui/components/container-dot/template"], function (_exports, _jquery, _platform, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    resourceActions: Ember.inject.service('resource-actions'),
    tooltipService: Ember.inject.service('tooltip'),
    router: Ember.inject.service(),
    layout: _template.default,
    model: null,
    tagName: 'div',
    classNames: ['vertical-middle'],
    type: 'tooltip-action-menu',
    template: 'tooltip-container-dot',
    alt: Ember.computed('model.{displayState,displayName}', function () {
      return "".concat(this.get('model.displayName'), ": ").concat(this.get('model.displayState'));
    }),
    resourceActionsObserver: Ember.on('init', Ember.observer('resourceActions.open', function () {
      if (this.get('tooltipService.openedViaContextClick')) {
        this.get('tooltipService').set('openedViaContextClick', false);
      }
    })),
    click: function click(event) {
      this.details(event);
      this.get('tooltipService').hide();
    },
    details: function details()
    /* event*/
    {
      var route = 'pod';

      if (this.get('model.isVm')) {
        route = 'virtualmachine';
      }

      this.get('router').transitionTo(route, this.get('model.id'));
    },
    contextMenu: function contextMenu(event) {
      if ((0, _platform.isMore)(event)) {
        return;
      }

      event.preventDefault();

      if (this.get('type') === 'tooltip-action-menu') {
        this.get('resourceActions').set('open', true);
        this.get('tooltipService').set('openedViaContextClick', true);
        (0, _jquery.default)('.container-tooltip .more-actions').trigger('click');
      } else {
        this.get('resourceActions').setActionItems(this.get('model'), {});
      }
    }
  });

  _exports.default = _default;
});