define("ui/components/cru-dns/component", ["exports", "shared/mixins/view-new-edit", "ui/components/cru-dns/template", "ui/utils/errors", "ui/models/service", "shared/mixins/child-hook"], function (_exports, _viewNewEdit, _template, _errors, _service, _childHook) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var LOAD_BALANCER = 'LoadBalancer';
  var NODE_PORT = 'NodePort';
  var EXTERNAL_NAME = 'ExternalName';
  var CLUSTER_IP = 'ClusterIP';
  var HEADLESS = 'Headless';
  var KIND_CHOICES = [{
    label: 'editDns.kind.headless',
    value: HEADLESS
  }, {
    label: 'editDns.kind.clusterIP',
    value: CLUSTER_IP
  }, {
    label: 'editDns.kind.loadBalancer',
    value: LOAD_BALANCER
  }, {
    label: 'editDns.kind.nodePort',
    value: NODE_PORT
  }];

  var _default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    intl: Ember.inject.service(),
    capabilities: Ember.inject.service(),
    layout: _template.default,
    model: null,
    recordType: null,
    timeoutSeconds: null,
    kindChoices: null,
    namespace: Ember.computed.alias('model.namespace'),
    init: function init() {
      this._super.apply(this, arguments);

      var type = Ember.get(this, 'model.recordType') || _service.ARECORD;

      Ember.setProperties(this, {
        recordType: type
      });

      if (Ember.get(this, 'model.sessionAffinityConfig.clientIP.timeoutSeconds')) {
        Ember.set(this, 'timeoutSeconds', Ember.get(this, 'model.sessionAffinityConfig.clientIP.timeoutSeconds'));
      }

      this.initKindChoices();
    },
    actions: {
      setAlias: function setAlias(ids) {
        Ember.set(this, 'model.targetDnsRecordIds', ids);
      },
      setWorkload: function setWorkload(ids) {
        Ember.set(this, 'model.targetWorkloadIds', ids);
      },
      setSelector: function setSelector(map) {
        Ember.set(this, 'model.selector', map);
      },
      setLabels: function setLabels(labels) {
        var out = {};
        labels.forEach(function (row) {
          out[row.key] = row.value;
        });
        Ember.set(this, 'model.labels', out);
      }
    },
    timeoutSecondsDidChange: Ember.observer('timeoutSeconds', function () {
      var timeoutSeconds = Ember.get(this, 'timeoutSeconds');

      if (!Ember.get(this, 'model.sessionAffinityConfig.clientIP.timeoutSeconds')) {
        Ember.set(this, 'model.sessionAffinityConfig', {
          clientIP: {
            timeoutSeconds: timeoutSeconds
          }
        });
      } else {
        Ember.set(this, 'model.sessionAffinityConfig.clientIP.timeoutSeconds', timeoutSeconds);
      }
    }),
    kindDidChange: Ember.observer('kind', function () {
      var kind = Ember.get(this, 'kind');

      if (kind === HEADLESS) {
        kind = CLUSTER_IP;
        Ember.set(this, 'model.clusterIp', 'None');
      } else if (this.mode === 'new') {
        Ember.set(this, 'model.clusterIp', '');
      }

      if (kind === LOAD_BALANCER || kind === NODE_PORT) {
        Ember.set(this, 'model.externalTrafficPolicy', 'Cluster');
      } else {
        Ember.set(this, 'model.externalTrafficPolicy', null);
      }

      Ember.set(this, 'model.kind', kind);
    }),
    namespaceDidChange: Ember.observer('namespace.id', function () {
      var _this = this;

      if (Ember.get(this, 'recordType') === 'workload') {
        if (Ember.get(this, 'model.targetWorkloads').some(function (target) {
          return target.namespaceId !== Ember.get(_this, 'namespace.id');
        })) {
          Ember.setProperties(this, {
            'model.targetWorkloadIds': null,
            recordType: null
          });
          Ember.run.next(function () {
            Ember.set(_this, 'recordType', 'workload');
          });
        }
      }
    }),
    recordTypeDidChange: Ember.observer('recordType', function () {
      var recordType = Ember.get(this, 'recordType');

      if (recordType === _service.CNAME) {
        Ember.set(this, 'kind', EXTERNAL_NAME);
      } else {
        Ember.set(this, 'kind', HEADLESS);
      }
    }),
    showSessionAffinity: Ember.computed('isHeadless', 'kind', 'showMoreOptions', function () {
      return Ember.get(this, 'showMoreOptions') && Ember.get(this, 'kind') !== HEADLESS;
    }),
    showMoreOptions: Ember.computed('recordType', 'kind', function () {
      return _service.CNAME !== Ember.get(this, 'recordType');
    }),
    isHeadless: Ember.computed('kind', function () {
      return Ember.get(this, 'kind') === HEADLESS;
    }),

    /*
    targetServicesAsMaps: null,
    targetIpArray: null,
    stack: null,
    stackErrors: null,
    */
    workloadsChoices: Ember.computed('namespace.id', 'workloads.[]', function () {
      var namespaceId = Ember.get(this, 'namespace.id');
      return (Ember.get(this, 'workloads') || []).filter(function (w) {
        return Ember.get(w, 'namespaceId') === namespaceId;
      });
    }),
    initKindChoices: function initKindChoices() {
      var loadBalancerCapabilites = Ember.get(this, 'capabilities.loadBalancerCapabilites');

      if (Ember.get(this, 'model.kind') === CLUSTER_IP && Ember.get(this, 'model.clusterIp') === null) {
        Ember.set(this, 'kind', HEADLESS);
      } else if (Ember.get(this, 'model.kind')) {
        Ember.set(this, 'kind', Ember.get(this, 'model.kind'));
      }

      Ember.set(this, 'kindChoices', KIND_CHOICES.map(function (k) {
        var disabled = false;

        if (!loadBalancerCapabilites.l4LoadBalancerEnabled && Ember.get(k, 'value') === 'LoadBalancer') {
          disabled = true;
        }

        var out = {
          label: Ember.get(k, 'label'),
          value: Ember.get(k, 'value'),
          disabled: disabled
        };
        return out;
      }));
    },
    willSave: function willSave() {
      var _arguments = arguments,
          _this2 = this;

      Ember.get(this, 'model').clearTypesExcept(Ember.get(this, 'recordType'));

      if (Ember.get(this, 'mode') === 'edit' && Ember.get(this, 'recordType') === _service.WORKLOAD) {
        delete Ember.get(this, 'model')[_service.SELECTOR];
      }

      var ports = this.primaryResource.ports || [];

      if (this.primaryResource.kind !== LOAD_BALANCER && this.primaryResource.kind !== NODE_PORT) {
        ports.forEach(function (port) {
          return delete port['nodePort'];
        });
      }

      Ember.set(this, 'model.namespaceId', Ember.get(this, 'namespace.id') || '__placeholder__');
      var self = this;
      var sup = this._super;
      var errors = [];
      errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);
      Ember.set(this, 'errors', errors);

      if (Ember.get(errors, 'length') !== 0) {
        return false;
      }

      return this.applyHooks('_beforeSaveHooks').then(function () {
        Ember.set(_this2, 'model.namespaceId', Ember.get(_this2, 'namespace.id'));
        return sup.apply.apply(sup, [self].concat(_toConsumableArray(_arguments)));
      }).catch(function (err) {
        Ember.set(_this2, 'errors', [_errors.default.stringify(err)]);
      });
    },
    validate: function validate() {
      var errors = Ember.get(this, 'errors') || [];
      var intl = Ember.get(this, 'intl');
      var aliasTargets = Ember.get(this, 'model.targetDnsRecords') || [];
      var aliases = aliasTargets.length;
      var aliasesToCname = aliasTargets.filterBy('recordType', _service.CNAME).length;
      var selectorKeys = Object.keys(Ember.get(this, 'model.selector') || {}).length;
      var workloads = (Ember.get(this, 'model.targetWorkloads') || []).length;

      switch (Ember.get(this, 'recordType')) {
        case _service.ARECORD:
          if (!(Ember.get(this, 'model.ipAddresses') || []).any(function (ip) {
            return ip;
          })) {
            errors.pushObject(intl.t('editDns.errors.targetRequired'));
          }

          break;

        case _service.CNAME:
          if (!Ember.get(this, 'model.hostname')) {
            errors.pushObject(intl.t('editDns.errors.targetRequired'));
          }

          break;

        case _service.ALIAS:
          if (aliases < 1) {
            errors.pushObject(intl.t('editDns.errors.targetRequired'));
          }

          if (aliasesToCname > 1) {
            errors.pushObject(intl.t('editDns.errors.multipleCname'));
          }

          if (aliasesToCname >= 1 && aliases > aliasesToCname) {
            errors.pushObject(intl.t('editDns.errors.mixedAlias'));
          }

          break;

        case _service.WORKLOAD:
          if (workloads < 1) {
            errors.pushObject(intl.t('editDns.errors.targetRequired'));
          }

          break;

        case _service.SELECTOR:
          if (selectorKeys < 1) {
            errors.pushObject(intl.t('editDns.errors.selectorRequired'));
          }

          break;
      }

      Ember.set(this, 'errors', errors);
      return errors.length === 0;
    }
  });

  _exports.default = _default;
});