define("ui/components/container-dot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ra09ZQ40",
    "block": "{\"symbols\":[\"container\"],\"statements\":[[6,[37,7],[[35,0,[\"hasSidekicks\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"dot-group bg-default hand\"],[12],[2,\"\\n\"],[6,[37,4],null,[[\"type\",\"model\",\"tooltipTemplate\",\"placement\",\"tagName\",\"tooltipFor\"],[[35,3],[35,0],[35,2],\"top\",\"span\",\"containerDot\"]],[[\"default\"],[{\"statements\":[[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,0,[\"containers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[15,0,[31,[[32,1,[\"stateIcon\"]],\" \",[32,1,[\"stateColor\"]],\" dot\"]]],[15,\"alt\",[34,1]],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],null,[[\"type\",\"model\",\"tooltipTemplate\",\"placement\",\"tagName\",\"tooltipFor\"],[[35,3],[35,0],[35,2],\"top\",\"span\",\"containerDot\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[31,[[34,0,[\"stateIcon\"]],\" \",[34,0,[\"stateColor\"]],\" dot hand\"]]],[15,\"alt\",[34,1]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"alt\",\"template\",\"type\",\"tooltip-element\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "ui/components/container-dot/template.hbs"
    }
  });

  _exports.default = _default;
});