define("ui/models/nodegroup", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    type: 'nodegroup',
    validationErrors: function validationErrors() {
      var errors = [];

      if ((this === null || this === void 0 ? void 0 : this.requestSpotInstances) && Ember.isEmpty(this === null || this === void 0 ? void 0 : this.spotInstanceTypes)) {
        errors.push('Node Groups requesting spot instances must include one or more spot instance types.');
      }

      if (errors.length > 0) {
        return errors;
      }

      errors = this._super.apply(this, arguments);
      return errors;
    }
  });

  _exports.default = _default;
});