define("ui/authenticated/cluster/route", ["exports", "ui/mixins/preload", "ui/utils/constants"], function (_exports, _preload, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var VALID_ROUTES = ['authenticated.cluster.nodes', 'authenticated.cluster.storage.classes', 'authenticated.cluster.storage.persistent-volumes', 'authenticated.cluster.notifier', 'authenticated.cluster.alert', 'authenticated.cluster.logging', 'authenticated.cluster.monitoring', 'authenticated.cluster.security.members.index', 'authenticated.cluster.projects', 'authenticated.cluster.quotas', 'authenticated.cluster.istio'];

  var _default = Ember.Route.extend(_preload.default, {
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    settings: Ember.inject.service(),
    model: function model(params, transition) {
      var _this = this;

      return Ember.get(this, 'globalStore').find('cluster', params.cluster_id).then(function (cluster) {
        var hideLocalCluster = Ember.get(_this.settings, 'shouldHideLocalCluster');

        if (hideLocalCluster && Ember.get(cluster, 'id') === 'local') {
          return _this.replaceWith('authenticated');
        }

        return Ember.get(_this, 'scope').startSwitchToCluster(cluster).then(function () {
          if (Ember.get(cluster, 'isReady')) {
            var preloads = [_this.preload('clusterScan', 'globalStore'), _this.preload('namespace', 'clusterStore'), _this.preload('persistentVolume', 'clusterStore'), _this.preload('storageClass', 'clusterStore')];

            if (Ember.get(cluster, 'isRKE')) {
              preloads.push(_this.preload('etcdBackup', 'globalStore'));
            }

            return _this.loadSchemas('clusterStore').then(function () {
              return Ember.RSVP.all(preloads).then(function () {
                return cluster;
              });
            });
          } else {
            return cluster;
          }
        }).catch(function (err) {
          // @TODO-2.0 right now the API can't return schemas for a not-active cluster
          if (err.status === 404) {
            return cluster;
          } else {
            return Ember.RSVP.reject(err);
          }
        });
      }).catch(function (err) {
        return _this.loadingError(err, transition);
      });
    },
    afterModel: function afterModel(model) {
      return Ember.get(this, 'scope').finishSwitchToCluster(model);
    },
    redirect: function redirect(router, transition) {
      var route = this.get("session.".concat(_constants.default.SESSION.CLUSTER_ROUTE));

      if (transition.targetName === 'authenticated.cluster.index' && VALID_ROUTES.includes(route)) {
        this.replaceWith(route);
      }
    },
    actions: {
      becameReady: function becameReady() {
        Ember.get(this, 'clusterStore').reset();
        this.refresh();
      },
      importYaml: function importYaml() {
        this.modalService.toggleModal('modal-import', {
          escToClose: true,
          clusterOnly: true
        });
      }
    }
  });

  _exports.default = _default;
});