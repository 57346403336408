define("ui/authenticated/cluster/storage/persistent-volumes/detail/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O4kEISYk",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"mode\",\"model\",\"done\",\"cancel\"],[\"edit\",[35,1],[30,[36,0],[[32,0],\"back\"],null],[30,[36,0],[[32,0],\"back\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"cru-persistent-volume\"]}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/storage/persistent-volumes/detail/edit/template.hbs"
    }
  });

  _exports.default = _default;
});