define("ui/authenticated/cluster/projects/new-ns/controller", ["exports", "ui/mixins/new-or-edit", "ui/utils/constants"], function (_exports, _newOrEdit, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ISTIO_INJECTION = 'istio-injection';
  var ENABLED = 'enabled';

  var _default = Ember.Controller.extend(_newOrEdit.default, {
    scope: Ember.inject.service(),
    queryParams: ['addTo', 'from'],
    addTo: null,
    from: null,
    istioInjection: false,
    primaryResource: Ember.computed.alias('model.namespace'),
    actions: {
      cancel: function cancel() {
        var backTo = Ember.get(this, 'session').get(_constants.default.SESSION.BACK_TO);

        if (backTo) {
          this.transitionToRoute('authenticated.project.ns.index', Ember.get(this, 'addTo'));
        } else {
          this.transitionToRoute('authenticated.cluster.projects.index');
        }
      },
      updateNsQuota: function updateNsQuota(quota) {
        if (quota) {
          Ember.set(this, 'primaryResource.resourceQuota', {
            limit: quota
          });
        } else {
          Ember.set(this, 'primaryResource.resourceQuota', null);
        }
      },
      updateContainerDefault: function updateContainerDefault(limit) {
        Ember.set(this, 'primaryResource.containerDefaultResourceLimit', limit);
      },
      toggleAutoInject: function toggleAutoInject() {
        Ember.set(this, 'istioInjection', !Ember.get(this, 'istioInjection'));
      },
      setLabels: function setLabels(labels) {
        var out = {};
        labels.forEach(function (row) {
          out[row.key] = row.value;
        });
        Ember.set(this, 'primaryResource.labels', out);
      }
    },
    projectDidChange: Ember.observer('primaryResource.project.id', function () {
      var _this = this;

      Ember.set(this, 'switchingProject', true);
      Ember.run.next(function () {
        Ember.set(_this, 'switchingProject', false);
      });

      if (!Ember.get(this, 'primaryResource.project.resourceQuota')) {
        Ember.set(this, 'primaryResource.resourceQuota', null);
      }
    }),
    allProjects: Ember.computed('model.allProjects', 'scope.currentCluster.id', function () {
      return Ember.get(this, 'model.allProjects').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'));
    }),
    projectLimit: Ember.computed('allProjects', 'primaryResource.projectId', 'primaryResource.resourceQuota.limit', function () {
      var projectId = Ember.get(this, 'primaryResource.projectId');
      var project = Ember.get(this, 'allProjects').findBy('id', projectId);
      return Ember.get(project, 'resourceQuota.limit');
    }),
    projectUsedLimit: Ember.computed('allProjects', 'primaryResource.projectId', 'primaryResource.resourceQuota.limit', function () {
      var projectId = Ember.get(this, 'primaryResource.projectId');
      var project = Ember.get(this, 'allProjects').findBy('id', projectId);
      return Ember.get(project, 'resourceQuota.usedLimit');
    }),
    nsDefaultQuota: Ember.computed('allProjects', 'primaryResource.projectId', 'primaryResource.resourceQuota.limit', function () {
      var projectId = Ember.get(this, 'primaryResource.projectId');
      var project = Ember.get(this, 'allProjects').findBy('id', projectId);
      return Ember.get(project, 'namespaceDefaultResourceQuota.limit');
    }),
    nameExists: Ember.computed('model.namespaces.@each.name', 'primaryResource.{id,name}', function () {
      var name = Ember.get(this, 'primaryResource.name');

      if (name) {
        var found = Ember.get(this, 'model.namespaces').findBy('name', name);
        return found && Ember.get(this, 'primaryResource.id') !== Ember.get(found, 'id');
      }

      return false;
    }),
    willSave: function willSave() {
      var isEnabled = Ember.get(this, 'istioInjection');

      var labels = _objectSpread({}, Ember.get(this, 'primaryResource.labels'));

      if (isEnabled) {
        labels[ISTIO_INJECTION] = ENABLED;
      }

      Ember.set(this, 'primaryResource.labels', labels);
      return this._super.apply(this, arguments);
    },
    validate: function validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];
      var quotaErrors = Ember.get(this, 'primaryResource').validateResourceQuota();

      if (quotaErrors.length > 0) {
        errors.pushObjects(quotaErrors);
      }

      Ember.set(this, 'errors', errors);
      return Ember.get(this, 'errors.length') === 0;
    },
    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });

  _exports.default = _default;
});