define("ui/components/modal-delete-group-role-bindings/component", ["exports", "ui/mixins/modal-base", "ui/components/modal-delete-group-role-bindings/template"], function (_exports, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    intl: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['medium-modal'],
    mappedGroupRoleBindingNamesIds: Ember.computed.alias('modalService.modalOpts.model.mappedGroupRoleBindingNamesIds'),
    actions: {
      save: function save(cb) {
        var _this = this;

        Ember.run.next(function () {
          _this.modalService.modalOpts.model.removeRoleBindings(cb).then(function () {
            _this.send('cancel');
          });
        });
      }
    }
  });

  _exports.default = _default;
});