define("ui/components/settings/telemetry-opt/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/pgngcnv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"telemetryOpt.header\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"p\"],[14,0,\"text-muted\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"telemetryOpt.subtext\"],[[\"appName\",\"htmlSafe\"],[[35,1,[\"appName\"]],true]]]],[2,\"\\n  \"],[10,\"a\"],[15,6,[31,[[34,1,[\"docsBase\"]],\"/faq/telemetry\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener nofollow\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"telemetryOpt.learnMore.label\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"col-inline checkbox\"],[12],[2,\"\\n  \"],[10,\"label\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"type\",\"checked\"],[\"checkbox\",[35,2]]]]],[2,\"\\n    \"],[1,[30,[36,0],[\"telemetryOpt.label\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"cancelDisabled\",\"editing\",\"save\"],[true,true,[30,[36,4],[[32,0],\"save\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"settings\",\"optIn\",\"input\",\"action\",\"save-cancel\"]}",
    "meta": {
      "moduleName": "ui/components/settings/telemetry-opt/template.hbs"
    }
  });

  _exports.default = _default;
});