define("ui/mixins/logging-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var DEFAULT_TARGET_TYPE = 'none';

  var _default = Ember.Mixin.create({
    // needs to override the type props
    type: null,
    patch: function patch() {
      var t = Ember.get(this, 'targetType');
      var store = Ember.get(this, 'store');
      var nue = store.createRecord({
        type: this.get('type')
      });
      var map = Ember.Object.create({});
      var loggingTagets = ['kafka', 'elasticsearch', 'splunk', 'syslog', 'fluentForwarder', 'customTarget'];
      loggingTagets.forEach(function (key) {
        var config;

        if (key === 'fluentForwarder') {
          config = store.createRecord({
            type: "fluentForwarderConfig"
          });
        } else if (key === 'customTarget') {
          config = store.createRecord({
            type: "customTargetConfig"
          });
          Ember.setProperties(config, {
            clientKey: '',
            clientCert: '',
            certificate: '',
            content: "<match *>\n  @type elasticsearch\n\n</match>"
          });
        } else {
          config = store.createRecord({
            type: "".concat(key, "Config")
          });
        }

        nue.set('config', config);
        Ember.set(map, key, nue.clone());
      });
      this.setProperties(map);

      if (t && t !== 'none') {
        var _Ember$setProperties;

        Ember.setProperties(this, (_Ember$setProperties = {}, _defineProperty(_Ember$setProperties, "".concat(t, ".config"), Ember.get(this, "".concat(t, "Config"))), _defineProperty(_Ember$setProperties, "".concat(t, ".outputFlushInterval"), Ember.get(this, "outputFlushInterval")), _defineProperty(_Ember$setProperties, "".concat(t, ".outputTags"), Ember.get(this, "outputTags")), _defineProperty(_Ember$setProperties, "".concat(t, ".dockerRootDir"), Ember.get(this, 'dockerRootDir')), _defineProperty(_Ember$setProperties, "".concat(t, ".includeSystemComponent"), Ember.get(this, 'includeSystemComponent')), _Ember$setProperties));
      }

      return this;
    },
    targetType: Ember.computed('elasticsearchConfig', 'splunkConfig', 'kafkaConfig', 'syslogConfig', 'fluentForwarderConfig', 'customTargetConfig', function () {
      var customTargetConfig = this.customTargetConfig,
          elasticsearchConfig = this.elasticsearchConfig,
          splunkConfig = this.splunkConfig,
          syslogConfig = this.syslogConfig,
          kafkaConfig = this.kafkaConfig,
          fluentForwarderConfig = this.fluentForwarderConfig;

      if (customTargetConfig) {
        return 'customTarget';
      }

      if (elasticsearchConfig) {
        return 'elasticsearch';
      }

      if (splunkConfig) {
        return 'splunk';
      }

      if (syslogConfig) {
        return 'syslog';
      }

      if (kafkaConfig) {
        return 'kafka';
      }

      if (fluentForwarderConfig) {
        return 'fluentForwarder';
      }

      return DEFAULT_TARGET_TYPE;
    }),
    sslTargetType: Ember.computed('elasticsearchConfig', 'splunkConfig', 'kafkaConfig', 'syslogConfig', 'fluentForwarderConfig', function () {
      var es = Ember.get(this, 'elasticsearchConfig');
      var splunk = Ember.get(this, 'splunkConfig');
      var kafka = Ember.get(this, 'kafkaConfig');
      var syslog = Ember.get(this, 'syslogConfig');
      var fluentd = Ember.get(this, 'fluentForwarderConfig');

      if (es) {
        return 'elasticsearch';
      }

      if (splunk) {
        return 'splunk';
      }

      if (syslog) {
        return 'syslog';
      }

      if (kafka) {
        return 'kafka';
      }

      if (fluentd) {
        return 'fluentForwarder';
      }

      return DEFAULT_TARGET_TYPE;
    })
  });

  _exports.default = _default;
});