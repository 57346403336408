define("ui/components/pod-metrics/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XZX1pd2n",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[12],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"queryAction\",\"state\"],[[30,[36,1],[[32,0],\"query\"],null],[35,0]]]]],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"graphs\",\"loading\",\"noGraphs\"],[[35,3],[35,0,[\"loading\"]],[35,0,[\"noGraphs\"]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"state\",\"action\",\"metrics-action\",\"graphs\",\"metrics-graph\"]}",
    "meta": {
      "moduleName": "ui/components/pod-metrics/template.hbs"
    }
  });

  _exports.default = _default;
});