define("ui/components/accordion-pod/component", ["exports", "shared/mixins/manage-labels", "ui/components/accordion-pod/template"], function (_exports, _manageLabels, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_manageLabels.default, {
    layout: _template.default,
    model: null,
    expandOnInit: true,
    sortBy: 'displayState',
    showKind: true,
    descending: true,
    initExpand: true,
    headers: [{
      name: 'displayState',
      sort: ['displayState', 'sortName', 'id'],
      translationKey: 'generic.state',
      width: 120
    }, {
      name: 'name',
      sort: ['sortName', 'id'],
      translationKey: 'generic.name',
      width: 350
    }, {
      name: 'displayImage',
      sort: ['displayImage', 'displayIp', 'created'],
      translationKey: 'generic.image'
    }, {
      name: 'node',
      sort: ['node.sortName', 'node.ipAddress', 'node.id'],
      translationKey: 'generic.node'
    }]
  });

  _exports.default = _default;
});