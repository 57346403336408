define("ui/authenticated/project/dns/detail/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ijDWa8Yq",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"model\",\"dnsRecords\",\"workloads\",\"mode\"],[[35,0,[\"record\"]],[35,0,[\"dnsRecords\"]],[35,0,[\"workloads\"]],\"view\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"cru-dns\"]}",
    "meta": {
      "moduleName": "ui/authenticated/project/dns/detail/index/template.hbs"
    }
  });

  _exports.default = _default;
});