define("ui/authenticated/project/certificates/detail/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nEbavH7I",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"originalModel\",\"model\",\"mode\",\"done\",\"cancel\"],[[35,2],[35,1],\"edit\",[30,[36,0],[[32,0],\"back\"],null],[30,[36,0],[[32,0],\"back\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"originalModel\",\"cru-certificate\"]}",
    "meta": {
      "moduleName": "ui/authenticated/project/certificates/detail/edit/template.hbs"
    }
  });

  _exports.default = _default;
});