define("ui/authenticated/project/security/members/edit/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bnD7qsvX",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"model\",\"editing\",\"roleType\"],[[35,0],true,\"project\"]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"new-edit-role\"]}",
    "meta": {
      "moduleName": "ui/authenticated/project/security/members/edit/template.hbs"
    }
  });

  _exports.default = _default;
});