define("ui/components/modal-new-pvc/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "seK1tWw5",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,4],null,[[\"model\",\"namespace\",\"selectNamespace\",\"mode\",\"actuallySave\",\"doSave\",\"cancel\"],[[35,1],[35,3],false,[30,[36,2],[[35,1,[\"id\"]],\"edit\",\"new\"],null],false,[30,[36,0],[[32,0],\"doSave\"],null],[30,[36,0],[[32,0],\"cancel\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"if\",\"namespace\",\"cru-persistent-volume-claim\"]}",
    "meta": {
      "moduleName": "ui/components/modal-new-pvc/template.hbs"
    }
  });

  _exports.default = _default;
});