define("ui/authenticated/cluster/security/members/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "O5y/KmQl",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header clearfix\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pull-left\"],[12],[2,\"\\n    \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"membersPage.add.title\"],[[\"type\"],[\"Cluster\"]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"section\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"box mt-30\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"model\",\"type\",\"cancel\",\"doneSaving\"],[[35,2],\"cluster\",[30,[36,1],[[32,0],\"doneSaving\"],null],[30,[36,1],[[32,0],\"doneSaving\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"model\",\"form-scoped-roles\"]}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/security/members/new/template.hbs"
    }
  });

  _exports.default = _default;
});