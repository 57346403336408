define("ui/components/modal-edit-node-pool/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NVBAcSbj",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"editNodePool.title\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"section\"],[14,0,\"horizontal-form container-fluid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row inline-form\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-12\"],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"model\",\"nodeTemplateTaints\",\"expandOnInit\"],[[35,1],[35,1,[\"nodeTemplate\",\"nodeTaints\"]],true]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,4],null,[[\"errors\"],[[35,3]]]]],[2,\"\\n\"],[1,[30,[36,6],null,[[\"editing\",\"save\",\"cancel\"],[true,[30,[36,5],[[32,0],\"save\"],null],[30,[36,5],[[32,0],\"cancel\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"t\",\"model\",\"form-node-taints\",\"errors\",\"top-errors\",\"action\",\"save-cancel\"]}",
    "meta": {
      "moduleName": "ui/components/modal-edit-node-pool/template.hbs"
    }
  });

  _exports.default = _default;
});