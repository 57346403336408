define("ui/components/settings/landing-page/component", ["exports", "ui/utils/constants", "ui/components/settings/landing-page/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    prefs: Ember.inject.service(),
    layout: _template.default,
    value: null,
    init: function init() {
      this._super.apply(this, arguments);

      var value = this.get("prefs.".concat(_constants.default.PREFS.LANDING));
      this.set('value', value);
    },
    valueChanged: Ember.observer('value', function () {
      this.set("prefs.".concat(_constants.default.PREFS.LANDING), this.value);
    })
  });

  _exports.default = _default;
});