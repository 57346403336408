define("ui/authenticated/cluster/storage/persistent-volumes/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kzAQZ41b",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header clearfix\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"right-buttons\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm bg-default mr-10\"],[16,\"disabled\",[30,[36,1],null,[[\"resource\",\"scope\",\"permission\"],[\"persistentvolume\",\"cluster\",\"create\"]]]],[24,4,\"button\"],[4,[38,2],[[32,0],\"importYaml\"],null],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"nav.containers.importCompose\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,3],null,[[\"class\",\"disabled\",\"route\"],[\"btn btn-sm bg-primary\",[30,[36,1],null,[[\"resource\",\"scope\",\"permission\"],[\"persistentvolume\",\"cluster\",\"create\"]]],\"authenticated.cluster.storage.persistent-volumes.new\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"persistentVolumePage.addActionLabel\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"h1\"],[12],[1,[30,[36,0],[\"persistentVolumePage.header\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"rows\"],[[35,4,[\"persistentVolumes\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"rbac-prevents\",\"action\",\"link-to\",\"model\",\"persistent-volume-table\"]}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/storage/persistent-volumes/index/template.hbs"
    }
  });

  _exports.default = _default;
});