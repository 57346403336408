define("ui/components/hpa-metric-row/component", ["exports", "ui/components/hpa-metric-row/template", "shared/utils/util", "shared/utils/parse-unit"], function (_exports, _template, _util, _parseUnit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var RESOURCE = 'Resource';
  var PODS = 'Pods';
  var OBJECT = 'Object';
  var EXTERNAL = 'External';
  var MEMORY = 'memory';
  var CPU = 'cpu';
  var VALUE = 'Value';
  var AVERAGE_VALUE = 'AverageValue';
  var AVERAGE_UTILIZATION = 'Utilization';
  var METRICS_TYPES = [{
    label: 'cruHpa.metrics.types.resource',
    value: RESOURCE
  }, {
    label: 'cruHpa.metrics.types.pods',
    value: PODS
  }, {
    label: 'cruHpa.metrics.types.object',
    value: OBJECT
  }, {
    label: 'cruHpa.metrics.types.external',
    value: EXTERNAL
  }];
  var RESOURCE_TYPES = [{
    label: 'cruHpa.metrics.name.resources.cpu',
    value: CPU
  }, {
    label: 'cruHpa.metrics.name.resources.memory',
    value: MEMORY
  }];
  var TARGET_TYPES = {
    VALUE: {
      label: 'cruHpa.metrics.target.options.value',
      value: VALUE
    },
    AVERAGE_VALUE: {
      label: 'cruHpa.metrics.target.options.averageValue',
      value: AVERAGE_VALUE
    },
    AVERAGE_UTILIZATION: {
      label: 'cruHpa.metrics.target.options.averageUtilization',
      value: AVERAGE_UTILIZATION
    }
  };

  var _default = Ember.Component.extend({
    layout: _template.default,
    editing: true,
    metric: null,
    typeChoices: METRICS_TYPES,
    resourceChoices: RESOURCE_TYPES,
    init: function init() {
      this._super.apply(this, arguments);

      var metricTargetType = Ember.get(this, 'metric.target.type');
      var metricType = Ember.get(this, 'metric.type');
      var metricName = Ember.get(this, 'metric.name');
      var averageValue = Ember.get(this, 'metric.target.averageValue');

      if (metricName === CPU && metricTargetType === AVERAGE_VALUE && metricType === RESOURCE) {
        Ember.set(this, 'metric.target.stringValue', (0, _util.convertToMillis)(averageValue));
      }

      if (metricName === MEMORY && metricTargetType === AVERAGE_VALUE && metricType === RESOURCE) {
        Ember.set(this, 'metric.target.stringValue', (0, _parseUnit.parseSi)(averageValue, 1024) / 1048576);
      }
    },
    actions: {
      removeMetric: function removeMetric(metric) {
        if (this.removeMetric) {
          this.removeMetric(metric);
        }
      },
      updateSelectorMatchLabels: function updateSelectorMatchLabels(labels) {
        if (Ember.get(this, 'metric.selector')) {
          Ember.set(this, 'metric.selector.matchLabels', labels);
        } else {
          Ember.set(this, 'metric.selector', {
            matchLabels: labels
          });
        }
      },
      updateSelectorMatchExpressions: function updateSelectorMatchExpressions(expressions) {
        if (Ember.get(this, 'metric.selector')) {
          Ember.set(this, 'metric.selector.matchExpressions', expressions);
        } else {
          Ember.set(this, 'metric.selector', {
            matchExpressions: expressions
          });
        }
      }
    },
    targetTypeDidChange: Ember.observer('metric.target.type', function () {
      var target = Ember.get(this, 'metric.target');
      Ember.setProperties(target, {
        utilization: null,
        averageValue: null,
        stringValue: null,
        value: null
      });
    }),
    metricTypeDidChange: Ember.observer('metric.type', function () {
      var metric = Ember.get(this, 'metric');
      var type = Ember.get(metric, 'type');

      if (type === RESOURCE) {
        delete metric['selector'];
        delete metric['describedObject'];
        Ember.setProperties(metric, {
          name: CPU,
          target: {
            type: AVERAGE_UTILIZATION,
            value: null,
            averageValue: null,
            stringValue: null,
            utilization: null
          }
        });
      } else if (type === EXTERNAL) {
        delete metric['describedObject'];
        Ember.setProperties(metric, {
          selector: null,
          name: null,
          target: {
            type: AVERAGE_VALUE,
            value: null,
            averageValue: null,
            utilization: null
          }
        });
      } else if (type === OBJECT) {
        Ember.setProperties(metric, {
          selector: null,
          name: null,
          describedObject: {},
          target: {
            type: VALUE,
            value: null,
            averageValue: null,
            utilization: null
          }
        });
      } else if (type === PODS) {
        delete metric['describedObject'];
        Ember.setProperties(metric, {
          name: null,
          selector: null,
          target: {
            type: AVERAGE_VALUE,
            value: null,
            averageValue: null,
            utilization: null
          }
        });
      }
    }),
    metricAverageValueDidChange: Ember.observer('metric.target.type', 'metric.type', 'metric.name', 'metric.target.stringValue', function () {
      var metricTargetType = Ember.get(this, 'metric.target.type');
      var metricType = Ember.get(this, 'metric.type');
      var metricName = Ember.get(this, 'metric.name');
      var stringValue = Ember.get(this, 'metric.target.stringValue');

      if (metricName === CPU && metricTargetType === AVERAGE_VALUE && metricType === RESOURCE) {
        Ember.set(this, 'metric.target.averageValue', stringValue ? "".concat(stringValue, "m") : null);
      }

      if (metricName === MEMORY && metricTargetType === AVERAGE_VALUE && metricType === RESOURCE) {
        Ember.set(this, 'metric.target.averageValue', stringValue ? "".concat(stringValue, "Mi") : null);
      }
    }),
    targetChoices: Ember.computed('metric.type', function () {
      switch (Ember.get(this, 'metric.type')) {
        case RESOURCE:
          return [TARGET_TYPES.AVERAGE_VALUE, TARGET_TYPES.AVERAGE_UTILIZATION];

        case PODS:
          return [TARGET_TYPES.AVERAGE_VALUE];

        case EXTERNAL:
          return [TARGET_TYPES.VALUE, TARGET_TYPES.AVERAGE_VALUE];

        case OBJECT:
          return [TARGET_TYPES.VALUE, TARGET_TYPES.AVERAGE_VALUE];

        default:
          return [];
      }
    }),
    showCpuReservationWarning: Ember.computed('metric.name', 'metric.type', 'metric.target.type', 'selectedWorkload.launchConfig.hasCpuReservation', function () {
      var targetType = Ember.get(this, 'metric.target.type');
      var type = Ember.get(this, 'metric.type');
      var name = Ember.get(this, 'metric.name');
      var selectedWorkload = Ember.get(this, 'selectedWorkload');
      var hasCpuReservation = Ember.get(this, 'selectedWorkload.launchConfig.hasCpuReservation');

      if (name === CPU && targetType === AVERAGE_UTILIZATION && type === RESOURCE && selectedWorkload) {
        return !hasCpuReservation;
      } else {
        return false;
      }
    }),
    showMemoryReservationWarning: Ember.computed('metric.name', 'metric.type', 'metric.target.type', 'selectedWorkload.launchConfig.hasMemoryReservation', function () {
      var targetType = Ember.get(this, 'metric.target.type');
      var type = Ember.get(this, 'metric.type');
      var name = Ember.get(this, 'metric.name');
      var selectedWorkload = Ember.get(this, 'selectedWorkload');
      var hasMemoryReservation = Ember.get(this, 'selectedWorkload.launchConfig.hasMemoryReservation');

      if (name === MEMORY && targetType === AVERAGE_UTILIZATION && type === RESOURCE && selectedWorkload) {
        return !hasMemoryReservation;
      } else {
        return false;
      }
    })
  });

  _exports.default = _default;
});