define("ui/models/scalehost", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    hostSelectorStr: Ember.computed('hostSelector', function () {
      var all = this.get('hostSelector') || [];
      return Object.keys(all).map(function (key) {
        var val = all[key];
        return key + (val ? "=".concat(val) : '');
      }).join(', ');
    }),
    validationErrors: function validationErrors() {
      var errors = this._super.apply(this, arguments);

      var min = parseInt(this.get('min'), 10);
      var max = parseInt(this.get('max'), 10);

      if (min && max && min > max) {
        errors.push('"Minimum Scale" cannot be greater than "Maximum Scale"');
      }

      return errors;
    }
  });

  _exports.default = _default;
});