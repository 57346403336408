define("ui/models/podsecuritypolicytemplate", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    router: Ember.inject.service(),
    type: 'podSecurityPolicyTemplate',
    canHaveLabels: true,
    actions: {
      edit: function edit() {
        this.get('router').transitionTo('global-admin.security.policies.edit', this.get('id'));
      }
    }
  });

  _exports.default = _default;
});