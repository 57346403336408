define("ui/authenticated/prefs/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    modal: Ember.inject.service(),
    newPassword: null,
    actions: {
      editPassword: function editPassword() {
        Ember.get(this, 'modal').toggleModal('modal-edit-password', {
          user: Ember.get(this, 'model.account')
        });
      }
    }
  });

  _exports.default = _default;
});