define("ui/components/progress-bar-multi/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D4fp/428",
    "block": "{\"symbols\":[\"obj\"],\"statements\":[[6,[37,5],null,[[\"type\",\"model\",\"tooltipTemplate\",\"aria-describedby\",\"tooltipFor\",\"inlineBlock\",\"classNames\"],[\"tooltip-basic\",[35,4],[35,3],\"tooltip-base\",\"progress-bar\",true,\"full-width\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"progress\"],[12],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[10,\"div\"],[15,0,[31,[\"progress-bar \",[32,1,[\"color\"]]]]],[15,5,[32,1,[\"css\"]]],[12],[13]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"pieces\",\"-track-array\",\"each\",\"tooltipTemplate\",\"tooltipContent\",\"tooltip-element\"]}",
    "meta": {
      "moduleName": "ui/components/progress-bar-multi/template.hbs"
    }
  });

  _exports.default = _default;
});