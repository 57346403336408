define("ui/components/input-files/component", ["exports", "ui/utils/platform", "ui/components/input-files/template", "jquery"], function (_exports, _platform, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    growl: Ember.inject.service(),
    layout: _template.default,
    initialFiles: null,
    accept: 'text/*',
    addActionLabel: 'generic.emptyString',
    uploadActionLabel: 'generic.emptyString',
    namePlaceholder: 'generic.emptyString',
    valuePlaceholder: 'generic.emptyString',
    ary: null,
    init: function init() {
      this._super.apply(this, arguments);

      var ary = [];
      var files = this.get('initialFiles') || {};
      Object.keys(files).forEach(function (name) {
        ary.push({
          name: name,
          value: files[name]
        });
      });
      this.set('ary', ary);
    },
    actions: {
      add: function add() {
        this.get('ary').pushObject({
          name: '',
          value: ''
        });
      },
      upload: function upload() {
        (0, _jquery.default)(this.element).find('.input-files').click();
      },
      remove: function remove(file) {
        this.get('ary').removeObject(file);
      }
    },
    onFilesChanged: Ember.observer('ary.@each.{name,value}', function () {
      var out = {};
      this.get('ary').forEach(function (file) {
        if (file.name && file.value) {
          out[file.name] = file.value;
        }
      });

      if (this.changed) {
        this.changed(out);
      }
    }),
    actualAccept: Ember.computed('accept', function () {
      if (_platform.isSafari) {
        return '';
      } else {
        return this.get('accept');
      }
    }),
    change: function change(event) {
      var _this = this;

      var ary = this.get('ary');
      var input = event.target;
      var handles = input.files;
      var names = [];

      if (handles) {
        // Remove empty files after a paste so config.yml goes away
        ary.slice().forEach(function (obj) {
          if (!obj.value.trim()) {
            ary.removeObject(obj);
          }
        });

        var _loop = function _loop(i) {
          var reader = new FileReader();

          reader.onload = function (event2) {
            _this.get('ary').pushObject({
              name: names[i],
              value: event2.target.result,
              uploaded: true
            });
          };

          reader.onerror = function (err) {
            Ember.get(_this, 'growl').fromError(Ember.get(err, 'srcElement.error.message'));
          };

          names[i] = handles[i].name;
          reader.readAsText(handles[i]);
        };

        for (var i = 0; i < handles.length; i++) {
          _loop(i);
        }

        input.value = '';
      }
    }
  });

  _exports.default = _default;
});