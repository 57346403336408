define("ui/authenticated/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ig1Y3Svd",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"pageScope\",\"goToPrevious\"],[[35,1],\"goToPrevious\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"main\"],[14,0,\"clearfix\"],[12],[2,\"\\n  \"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"showAbout\"],[\"showAbout\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"page-footer\",\"pageScope\",\"page-header\",\"isPopup\",\"unless\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ui/authenticated/template.hbs"
    }
  });

  _exports.default = _default;
});