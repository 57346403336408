define("ui/components/container/form-upgrade-stateful-set/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gFd+0YpP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"radio mb-10\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"disabled\",\"selection\",\"value\"],[[30,[36,2],[[35,1]],null],[35,0,[\"strategy\"]],\"RollingUpdate\"]]]],[2,\" \"],[1,[30,[36,4],[\"formUpgrade.strategy.rollingUpdate\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"radio mb-10\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[30,[36,3],null,[[\"disabled\",\"selection\",\"value\"],[[30,[36,2],[[35,1]],null],[35,0,[\"strategy\"]],\"OnDelete\"]]]],[2,\" \"],[1,[30,[36,4],[\"formUpgrade.strategy.onDelete\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"workloadConfig\",\"editing\",\"not\",\"radio-button\",\"t\"]}",
    "meta": {
      "moduleName": "ui/components/container/form-upgrade-stateful-set/template.hbs"
    }
  });

  _exports.default = _default;
});