define("ui/models/target", ["exports", "@rancher/ember-api-store/models/resource", "@rancher/ember-api-store/utils/denormalize"], function (_exports, _resource, _denormalize) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    project: (0, _denormalize.reference)('projectId'),
    projectName: Ember.computed('project.displayName', function () {
      return Ember.get(this, 'project.displayName');
    }),
    clusterName: Ember.computed('project.cluster.displayName', function () {
      return Ember.get(this, 'project.cluster.displayName');
    }),
    clusterId: Ember.computed('projectId', function () {
      var projectId = this.projectId;
      return projectId.split(':')[0];
    }),
    appLink: Ember.computed('projectId', 'appId', function () {
      var projectId = this.projectId;

      if (projectId) {
        return "".concat(projectId.split(':')[1], ":").concat(this.appId);
      }

      return null;
    })
  });

  _exports.default = _default;
});