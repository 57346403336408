define("ui/components/modal-about/component", ["exports", "shared/mixins/modal-base", "ui/components/modal-about/template", "shared/utils/download-files"], function (_exports, _modalBase, _template, _downloadFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_modalBase.default, {
    settings: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    growl: Ember.inject.service(),
    layout: _template.default,
    classNames: ['generic', 'about', 'medium-modal'],
    actions: {
      downloadLinuxImages: function downloadLinuxImages() {
        var _this = this;

        Ember.get(this, 'globalStore').rawRequest({
          url: "/v3/kontainerdrivers/rancher-images",
          method: 'GET'
        }).then(function (res) {
          (0, _downloadFiles.downloadFile)("rancher-linux-images.txt", Ember.get(res, 'body'));
        }).catch(function (error) {
          Ember.get(_this, 'growl').fromError('Error downloading Linux image list', error.message);
        });
      },
      downloadWindowsImages: function downloadWindowsImages() {
        var _this2 = this;

        Ember.get(this, 'globalStore').rawRequest({
          url: "/v3/kontainerdrivers/rancher-windows-images",
          method: 'GET'
        }).then(function (res) {
          (0, _downloadFiles.downloadFile)("rancher-windows-images.txt", Ember.get(res, 'body'));
        }).catch(function (error) {
          Ember.get(_this2, 'growl').fromError('Error downloading Windows image list', error.message);
        });
      }
    }
  });

  _exports.default = _default;
});