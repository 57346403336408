define("ui/components/cru-persistent-volume-claim/component", ["exports", "shared/utils/parse-unit", "shared/mixins/view-new-edit", "shared/mixins/child-hook", "ui/components/cru-persistent-volume-claim/template", "ui/utils/errors"], function (_exports, _parseUnit, _viewNewEdit, _childHook, _template, _errors2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    layout: _template.default,
    model: null,
    namespace: null,
    persistentVolumes: null,
    storageClasses: null,
    selectNamespace: true,
    actuallySave: true,
    useStorageClass: true,
    capacity: null,
    titleKey: 'cruPersistentVolumeClaim.title',
    canUseStorageClass: Ember.computed.gt('storageClasses.length', 0),
    didReceiveAttrs: function didReceiveAttrs() {
      if (!Ember.get(this, 'persistentVolumes')) {
        Ember.set(this, 'persistentVolumes', Ember.get(this, 'clusterStore').all('persistentVolume'));
      }

      if (!Ember.get(this, 'storageClasses')) {
        Ember.set(this, 'storageClasses', Ember.get(this, 'clusterStore').all('storageClass'));
      }

      if (!Ember.get(this, 'selectNamespace')) {
        Ember.set(this, 'primaryResource.namespaceId', Ember.get(this, 'namespace.id') || Ember.get(this, 'namespace.name'));
      }

      if (Ember.get(this, 'isNew')) {
        var capacity = Ember.get(this, 'primaryResource.resources.requests.storage');

        if (capacity) {
          var bytes = (0, _parseUnit.parseSi)(capacity);
          var gib = bytes / Math.pow(1024, 3);
          Ember.set(this, 'capacity', gib);
        }

        if (!Ember.get(this, 'canUseStorageClass')) {
          Ember.set(this, 'useStorageClass', false);
        }
      } else {
        Ember.set(this, 'capacity', 10);
      }
    },
    actions: {
      cancel: function cancel() {
        if (this.cancel) {
          this.cancel();
        }
      }
    },
    headerToken: Ember.computed('actuallySave', 'mode', function () {
      var k = 'cruPersistentVolumeClaim.';

      if (Ember.get(this, 'actuallySave')) {
        k += 'add.';
      } else {
        k += 'define.';
      }

      k += Ember.get(this, 'mode');
      return k;
    }),
    persistentVolumeChoices: Ember.computed('persistentVolumes.@each.{name,state}', function () {
      return Ember.get(this, 'persistentVolumes').map(function (v) {
        var label = Ember.get(v, 'displayName');
        var state = Ember.get(v, 'state');
        var disabled = state !== 'available';

        if (disabled) {
          label += " (".concat(state, ")");
        }

        return {
          label: label,
          disabled: disabled,
          value: Ember.get(v, 'id')
        };
      }).sortBy('label');
    }),
    willSave: function willSave() {
      var _arguments = arguments,
          _this = this;

      var pr = Ember.get(this, 'primaryResource');
      var intl = Ember.get(this, 'intl');

      if (Ember.get(this, 'useStorageClass')) {
        Ember.set(pr, 'volumeId', null);
        var capacity = Ember.get(this, 'capacity');

        if (capacity) {
          Ember.set(pr, 'resources', {
            requests: {
              storage: "".concat(capacity, "Gi")
            }
          });
        } else {
          var errors = [];
          errors.push(intl.t('validation.required', {
            key: intl.t('cruPersistentVolumeClaim.capacity.label')
          }));
          Ember.set(this, 'errors', errors);
          return false;
        }
      } else {
        Ember.set(pr, 'storageClassId', Ember.get(pr, 'persistentVolume.storageClassId') || null);
        Ember.set(pr, 'resources', {
          requests: Object.assign({}, Ember.get(pr, 'persistentVolume.capacity'))
        });
      }

      if (!Ember.get(this, 'actuallySave')) {
        var ok = this._super.apply(this, arguments);

        if (ok) {
          if (this.doSave) {
            this.doSave({
              pvc: pr
            });
          }

          if (this.done) {
            this.done();
          }
        }

        return false;
      }

      var self = this;
      var sup = this._super;

      if (Ember.get(this, 'selectNamespace')) {
        var _errors = [];

        _errors.pushObjects(Ember.get(this, 'namespaceErrors') || []);

        Ember.set(this, 'errors', _errors);

        if (Ember.get(_errors, 'length') !== 0) {
          return false;
        }

        return this.applyHooks('_beforeSaveHooks').then(function () {
          Ember.set(pr, 'namespaceId', Ember.get(_this, 'namespace.id'));
          return sup.apply.apply(sup, [self].concat(_toConsumableArray(_arguments)));
        }).catch(function (err) {
          Ember.set(_this, 'errors', [_errors2.default.stringify(err)]);
        });
      } else {
        if (!Ember.get(pr, 'namespaceId')) {
          Ember.set(pr, 'namespaceId', '__REPLACE_ME__');
        }

        return sup.apply.apply(sup, [self].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });

  _exports.default = _default;
});