define("ui/components/settings/server-url/component", ["exports", "ui/components/settings/server-url/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SCHEME = 'https://';

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    cancel: null,
    popupMode: false,
    initServerUrl: null,
    serverUrl: null,
    serverUrlSetting: null,
    setServerUrl: false,
    showHeader: true,
    urlInvalid: false,
    urlWarning: null,
    scheme: SCHEME,
    init: function init() {
      this._super.apply(this, arguments);

      var initServerUrl = Ember.get(this, 'initServerUrl');

      if (Ember.isEmpty(initServerUrl)) {
        Ember.set(this, 'serverUrl', window.location.host);
      } else {
        Ember.set(this, 'serverUrl', initServerUrl);
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        var elem = (0, _jquery.default)('INPUT')[0];

        if (elem) {
          elem.focus();
        }
      });
    },
    actions: {
      saveServerUrl: function saveServerUrl() {
        var _this2 = this;

        var setting = Ember.get(this, 'serverUrlSetting');
        Ember.set(setting, 'value', "".concat(SCHEME).concat(Ember.get(this, 'serverUrl')));
        setting.save().then(function () {
          if (!Ember.get(_this2, 'popupMode')) {
            Ember.get(_this2, 'router').replaceWith('authenticated');
          } else {
            _this2.send('cancel');
          }
        });
      },
      cancel: function cancel() {
        if (this.cancel) {
          this.cancel();
        }
      }
    }
  });

  _exports.default = _default;
});