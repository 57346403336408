define("ui/models/groupglobalrolebindings", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // THIS IS A FAKE RESOURCE
  // It exists purely to handle the weirdness around global role bindings with a group id. We need this fake model for the actions on the account group row.
  // Since we don't create a group when we create a globalRoleBinding when we list the bindings for a group we map them down into a collection of id's
  // in order to take action on those Id's we need to keep them around some how. Rather then modify the principal belonging to the group and risk permaenntly updating that somehow
  // I created this dumb model that is basically a fake group and collectes the globalRoleBinding Id's and groupPrincipalId. This gets passed as the model to the actions-menu.
  var _default = _resource.default.extend({
    growl: Ember.inject.service(),
    router: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    type: 'groupGloblaRoleBindings',
    globalRoleBindingIds: null,
    groupPrincipalId: null,
    canRemove: false,
    init: function init() {
      this._super.apply(this, arguments);

      if (!this.globalRoleBindingIds) {
        Ember.set(this, 'globalRoleBindingIds', []);
      }
    },
    canUpdate: Ember.computed('globalRoleBindingIds', 'groupPrincipalId', function () {
      var groupPrincipalId = this.groupPrincipalId,
          globalRoleBindingIds = this.globalRoleBindingIds;

      if (!Ember.isEmpty(groupPrincipalId) && !Ember.isEmpty(globalRoleBindingIds)) {
        return true;
      }

      return false;
    }),
    availableActions: Ember.computed('canUpdate', 'globalRoleBindingIds', 'groupPrincipalId', function () {
      var out = [{
        label: 'action.edit',
        icon: 'icon icon-edit',
        action: 'update',
        enabled: this.canUpdate
      }, {
        label: 'action.remove',
        icon: 'icon icon-trash',
        action: 'remove',
        altAction: 'bypassConfirmDelete',
        enabled: this.canUpdate
      }];
      return out;
    }),
    actions: {
      update: function update() {
        this.router.transitionTo('global-admin.security.accounts.edit-group', this.groupPrincipalId);
      },
      remove: function remove() {
        this.modalService.toggleModal('modal-delete-group-role-bindings', {
          model: this
        });
      },
      bypassConfirmDelete: function bypassConfirmDelete(cb) {
        this.removeRoleBindings(cb);
      }
    },
    removeRoleBindings: function removeRoleBindings(cb) {
      var _this = this;

      var _this$mappedGroupRole = this.mappedGroupRoleBindingNamesIds,
          mappedGroupRoleBindingNamesIds = _this$mappedGroupRole === void 0 ? [] : _this$mappedGroupRole;
      var promises = [];
      var globalRoleBindings = mappedGroupRoleBindingNamesIds.filterBy('globalRoleBinding').mapBy('globalRoleBinding');

      if (!Ember.isEmpty(globalRoleBindings) && globalRoleBindings.length > 0) {
        globalRoleBindings.forEach(function (grb) {
          var prom = _this.globalStore.rawRequest({
            url: grb.links.remove,
            method: 'DELETE'
          });

          promises.push(prom);
        });
      }

      return Ember.RSVP.all(promises).then(function (resp) {
        _this.growl.success('Success', "You've successfully removed the global role ".concat(_this.groupPrincipalName));

        if (cb) {
          return cb(resp);
        }

        return resp;
      }).catch(function (err) {
        if (cb) {
          return cb(err);
        }

        return err;
      });
    }
  });

  _exports.default = _default;
});