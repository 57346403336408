define("ui/components/namespace-resource-quota/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XfgJfHbu",
    "block": "{\"symbols\":[\"quota\"],\"statements\":[[6,[37,3],[[35,5,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"table\"],[14,0,\"table fixed\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"th\"],[12],[1,[30,[36,0],[\"formResourceQuota.table.type.label\"],null]],[13],[2,\"\\n        \"],[10,\"th\"],[12],[1,[30,[36,0],[\"formResourceQuota.table.resources.label\"],null]],[13],[2,\"\\n        \"],[10,\"th\"],[12],[1,[30,[36,0],[\"formResourceQuota.table.value.label\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,4],null,[[\"quota\",\"editing\"],[[32,1],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],[\"formResourceQuota.table.noData\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"editing\",\"not\",\"if\",\"namespace-quota-row\",\"quotaArray\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ui/components/namespace-resource-quota/template.hbs"
    }
  });

  _exports.default = _default;
});