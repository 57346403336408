define("ui/models/sourcecodecredential", ["exports", "@rancher/ember-api-store/models/resource"], function (_exports, _resource) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _resource.default.extend({
    type: 'sourcecodecredential',
    username: Ember.computed('displayName', function () {
      return Ember.get(this, 'displayName');
    }),
    profilePicture: Ember.computed('avatarUrl', function () {
      return Ember.get(this, 'avatarUrl');
    }),
    profileUrl: Ember.computed('htmlUrl', function () {
      return Ember.get(this, 'htmlUrl');
    })
  });

  _exports.default = _default;
});