define("ui/authenticated/cluster/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "psoFO+PV",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],[[35,0,[\"isActive\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,5],null,[[\"color\"],[[30,[36,4],[[35,0,[\"stateBackground\"]],\"  m-0\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[1,[30,[36,3],[[30,[36,2],[[35,0,[\"isReady\"]],\"clusterDashboard.notActive\",\"clusterDashboard.notReady\"],null]],[[\"state\",\"htmlSafe\"],[[35,0,[\"displayState\"]],true]]]],[13],[2,\"\\n\"],[6,[37,2],[[35,0,[\"showTransitioningMessage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"p\"],[15,0,[31,[[34,0,[\"stateColor\"]]]]],[12],[1,[30,[36,1],[[35,0,[\"transitioningMessage\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[1,[30,[36,8],[[30,[36,7],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"uc-first\",\"if\",\"t\",\"concat\",\"banner-message\",\"unless\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/template.hbs"
    }
  });

  _exports.default = _default;
});