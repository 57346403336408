define("ui/components/form-node-affinity/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "k4vsS7Ff",
    "block": "{\"symbols\":[\"rule\"],\"statements\":[[6,[37,8],[[30,[36,7],[[30,[36,7],[[35,6]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,5],null,[[\"removeRule\",\"rule\",\"editing\"],[[30,[36,0],[[32,0],\"removeRule\"],null],[32,1],[35,2]]]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[6,[37,4],[[30,[36,3],[[35,2]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center text-muted pt-20 pb-20\"],[12],[1,[30,[36,1],[\"formNodeAffinity.noRules\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bg-link icon-btn pull-left\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"addRule\"],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"darken\"],[12],[10,\"i\"],[14,0,\"icon icon-plus text-small\"],[12],[13],[13],[2,\"\\n      \"],[10,\"span\"],[12],[1,[30,[36,1],[\"formNodeAffinity.addRuleLabel\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"editing\",\"not\",\"if\",\"form-node-selector\",\"rules\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "ui/components/form-node-affinity/template.hbs"
    }
  });

  _exports.default = _default;
});