define("ui/authenticated/cluster/storage/classes/detail/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "96cZr/wa",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"mode\",\"model\",\"persistentVolumes\"],[\"view\",[35,0,[\"storageclass\"]],[35,0,[\"persistentVolumes\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"cru-storage-class\"]}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/storage/classes/detail/index/template.hbs"
    }
  });

  _exports.default = _default;
});