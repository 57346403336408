define("ui/components/cluster/cis/scan/detail/nodes-table/table-row/component", ["exports", "ui/components/cluster/cis/scan/detail/nodes-table/table-row/template"], function (_exports, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    tagName: '',
    badgeState: Ember.computed('model.state', function () {
      var state = Ember.get(this, 'model.state');
      return {
        stateBackground: this.getStateBackground(state),
        displayState: state
      };
    }),
    getStateBackground: function getStateBackground(state) {
      switch (state) {
        case 'Pass':
          return 'bg-success';

        case 'Skipped':
        case 'N/A':
          return 'bg-warning';

        default:
          return 'bg-error';
      }
    }
  });

  _exports.default = _default;
});