define("ui/components/modal-kubectl/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2BmhJdvJ",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"url\",\"showProtip\",\"displayName\",\"dismiss\",\"disconncted\"],[[35,2],false,[35,1,[\"currentCluster\",\"displayName\"]],[30,[36,0],[[32,0],\"cancel\"],null],[30,[36,0],[[32,0],\"cancel\"],null]]]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn bg-primary\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"cancel\"],null],[12],[1,[30,[36,4],[\"generic.closeModal\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"scope\",\"url\",\"container-shell\",\"t\"]}",
    "meta": {
      "moduleName": "ui/components/modal-kubectl/template.hbs"
    }
  });

  _exports.default = _default;
});