define("ui/authenticated/cluster/notifier/index/route", ["exports", "ui/utils/constants"], function (_exports, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    globalStore: Ember.inject.service(),
    scope: Ember.inject.service(),
    model: function model()
    /* params, transition */
    {
      var cs = Ember.get(this, 'globalStore');
      var clusterId = Ember.get(this.scope, 'currentCluster.id');
      return Ember.RSVP.hash({
        notifiers: cs.find('notifier', null, {
          filter: {
            clusterId: clusterId
          }
        }).then(function () {
          return cs.all('notifier');
        })
      });
    },
    setDefaultRoute: Ember.on('activate', function () {
      Ember.set(this, "session.".concat(_constants.default.SESSION.CLUSTER_ROUTE), 'authenticated.cluster.notifier');
    })
  });

  _exports.default = _default;
});