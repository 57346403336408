define("ui/components/identity-avatar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PS+xTwgS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"],[6,[37,5],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[35,2],[35,3]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"a\"],[15,6,[31,[[34,2]]]],[14,\"target\",\"_blank\"],[12],[2,\"\\n      \"],[10,\"img\"],[15,\"src\",[31,[[34,0]]]],[15,\"width\",[31,[[34,1]]]],[15,\"height\",[31,[[34,1]]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"img\"],[15,\"src\",[31,[[34,0]]]],[15,\"width\",[31,[[34,1]]]],[15,\"height\",[31,[[34,1]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"gh-placeholder\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"avatarSrc\",\"size\",\"url\",\"link\",\"and\",\"if\"]}",
    "meta": {
      "moduleName": "ui/components/identity-avatar/template.hbs"
    }
  });

  _exports.default = _default;
});