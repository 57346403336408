define("ui/authenticated/project/hpa/detail/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    clusterStore: Ember.inject.service(),
    model: function model() {
      var store = Ember.get(this, 'store');
      var clusterStore = Ember.get(this, 'clusterStore');
      var original = this.modelFor('authenticated.project.hpa.detail').hpa;
      return Ember.RSVP.hash({
        deployments: store.findAll('workload').then(function (workloads) {
          return workloads.filter(function (w) {
            return w.type === 'statefulSet' || w.type === 'deployment';
          });
        }),
        apiServices: clusterStore.findAll('apiService'),
        hpa: original.clone()
      });
    }
  });

  _exports.default = _default;
});