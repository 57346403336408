define("ui/components/modal-container-logs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tzcPinsT",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"instance\",\"containerName\",\"dismiss\"],[[35,2],[35,1],[30,[36,0],[[32,0],\"cancel\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"containerName\",\"originalModel\",\"container-logs\"]}",
    "meta": {
      "moduleName": "ui/components/modal-container-logs/template.hbs"
    }
  });

  _exports.default = _default;
});