define("ui/catalog-tab/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WC4XgqDM",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,7],null,[[\"application\",\"category\",\"categoryAction\",\"launch\",\"launchRoute\",\"model\",\"istio\",\"parentRoute\",\"refresh\",\"showHeader\"],[[35,6],[35,5],[30,[36,0],[[32,0],\"categoryAction\"],null],[30,[36,0],[[32,0],\"launch\"],null],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"refresh\"],null],true]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"parentRoute\",\"istio\",\"model\",\"launchRoute\",\"category\",\"application\",\"catalog-index\"]}",
    "meta": {
      "moduleName": "ui/catalog-tab/index/template.hbs"
    }
  });

  _exports.default = _default;
});