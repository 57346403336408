define("ui/components/modal-telemetry/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5MgiJepq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"welcome-copy\"],[12],[2,\"\\n\"],[2,\"\\n\"],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"alert-header\"],[12],[1,[30,[36,1],[\"modalWelcome.header\"],[[\"appName\"],[[35,0,[\"appName\"]]]]]],[13],[2,\"\\n\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col span-4\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[31,[[34,2,[\"baseAssets\"]],\"assets/images/welcome-scene.svg\"]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-8\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[1,[30,[36,1],[\"telemetryOpt.subtext\"],[[\"appName\",\"htmlSafe\"],[[35,0,[\"appName\"]],true]]]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-inline checkbox pt-30 text-small\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[30,[36,4],null,[[\"type\",\"checked\"],[\"checkbox\",[35,3]]]]],[2,\" \"],[1,[30,[36,1],[\"telemetryOpt.label\"],null]],[13],[2,\" \"],[10,\"a\"],[15,6,[31,[[34,0,[\"docsBase\"]],\"/faq/telemetry\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener nofollow\"],[12],[1,[30,[36,1],[\"telemetryOpt.learnMore.label\"],null]],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"footer-actions\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bg-primary\"],[24,4,\"button\"],[4,[38,5],[[32,0],\"cancel\"],null],[12],[1,[30,[36,1],[\"modalWelcome.closeModal\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"settings\",\"t\",\"app\",\"optIn\",\"input\",\"action\"]}",
    "meta": {
      "moduleName": "ui/components/modal-telemetry/template.hbs"
    }
  });

  _exports.default = _default;
});