define("ui/components/namespace-quota-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LoaKJ38b",
    "block": "{\"symbols\":[],\"statements\":[[10,\"td\"],[14,0,\"pr-10\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"editing\",\"quota\"],[false,[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"pr-10\"],[12],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"values\",\"tooltipValues\",\"tooltipArrayOrString\",\"tooltipTemplate\",\"max\",\"minPercent\"],[[35,0,[\"currentProjectUse\"]],[35,0,[\"totalLimits\"]],\"array\",\"-ns-quota-progressbar\",[35,0,[\"max\"]],0]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"editing\",\"quota\",\"key\"],[[35,3],[35,0],\"value\"]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"quota\",\"resource-quota-select\",\"progress-bar-multi\",\"editing\",\"input-resource-quota\"]}",
    "meta": {
      "moduleName": "ui/components/namespace-quota-row/template.hbs"
    }
  });

  _exports.default = _default;
});