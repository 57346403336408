define("ui/authenticated/cluster/cluster-catalogs/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p0AysgCv",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"header clearfix\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"right-buttons\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn bg-primary btn-sm\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"add\"],null],[12],[2,\"\\n      \"],[1,[30,[36,1],[\"catalogSettings.more.addActionLabel\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"h1\"],[12],[1,[30,[36,1],[\"catalogSettings.header\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,3],null,[[\"catalogs\",\"mode\"],[[35,2],\"cluster\"]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"filtered\",\"custom-catalog\"]}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/cluster-catalogs/template.hbs"
    }
  });

  _exports.default = _default;
});