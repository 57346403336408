define("ui/models/notifier", ["exports", "@rancher/ember-api-store/models/resource", "ui/utils/constants", "moment"], function (_exports, _resource, _constants, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = _resource.default.extend({
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    type: 'notifier',
    displayNameAndType: Ember.computed('displayName', 'notifierType', function () {
      var upperCaseType = (Ember.get(this, 'notifierType') || '').replace(/^\S/, function (s) {
        return s.toUpperCase();
      });
      return "".concat(Ember.get(this, 'displayName'), " (").concat(upperCaseType, ")");
    }),
    notifierTableLabel: Ember.computed('dingtalkConfig', 'emailConfig', 'msteamsConfig', 'pagerdutyConfig', 'slackConfig', 'smtpConfig', 'webhookConfig', 'wechatConfig', function () {
      var sc = Ember.get(this, 'slackConfig');
      var pc = Ember.get(this, 'pagerdutyConfig');
      var ec = Ember.get(this, 'smtpConfig');
      var wc = Ember.get(this, 'webhookConfig');
      var wcc = Ember.get(this, 'wechatConfig');
      var dtc = Ember.get(this, 'dingtalkConfig');
      var msc = Ember.get(this, 'msteamsConfig');

      if (sc) {
        return _constants.default.NOTIFIER_TABLE_LABEL.SLACK;
      }

      if (pc) {
        return _constants.default.NOTIFIER_TABLE_LABEL.PAGERDUTY;
      }

      if (ec) {
        return _constants.default.NOTIFIER_TABLE_LABEL.SMTP;
      }

      if (wc) {
        return _constants.default.NOTIFIER_TABLE_LABEL.WEBHOOK;
      }

      if (wcc) {
        return _constants.default.NOTIFIER_TABLE_LABEL.WECHAT;
      }

      if (dtc) {
        return _constants.default.NOTIFIER_TABLE_LABEL.DINGTALK;
      }

      if (msc) {
        return _constants.default.NOTIFIER_TABLE_LABEL.MICROSOFTTEAMS;
      }

      return _constants.default.NOTIFIER_TABLE_LABEL.DEFAULT;
    }),
    notifierType: Ember.computed('dingtalkConfig', 'emailConfig', 'msteamsConfig', 'pagerdutyConfig', 'slackConfig', 'smtpConfig', 'webhookConfig', 'wechatConfig', function () {
      var sc = Ember.get(this, 'slackConfig');
      var pc = Ember.get(this, 'pagerdutyConfig');
      var ec = Ember.get(this, 'smtpConfig');
      var wc = Ember.get(this, 'webhookConfig');
      var wcc = Ember.get(this, 'wechatConfig');
      var dtc = Ember.get(this, 'dingtalkConfig');
      var msc = Ember.get(this, 'msteamsConfig');

      if (sc) {
        return 'slack';
      }

      if (pc) {
        return 'pagerduty';
      }

      if (ec) {
        return 'email';
      }

      if (wc) {
        return 'webhook';
      }

      if (wcc) {
        return 'wechat';
      }

      if (dtc) {
        return 'dingtalk';
      }

      if (msc) {
        return 'msteams';
      }

      return null;
    }),
    notifierValue: Ember.computed('emailConfig', 'pagerdutyConfig', 'slackConfig', 'smtpConfig', 'webhookConfig', 'wechatConfig', function () {
      var sc = Ember.get(this, 'slackConfig');
      var pc = Ember.get(this, 'pagerdutyConfig');
      var ec = Ember.get(this, 'smtpConfig');
      var wc = Ember.get(this, 'webhookConfig');
      var wcc = Ember.get(this, 'wechatConfig');

      if (sc) {
        return Ember.get(sc, 'defaultRecipient');
      }

      if (pc) {
        return Ember.get(pc, 'serviceKey');
      }

      if (ec) {
        return Ember.get(ec, 'defaultRecipient');
      }

      if (wc) {
        return Ember.get(wc, 'url');
      }

      if (wcc) {
        return Ember.get(wcc, 'defaultRecipient');
      }

      return '';
    }),
    displayCreated: Ember.computed('created', function () {
      var d = Ember.get(this, 'created');
      return (0, _moment.default)(d).fromNow();
    }),
    notifierLabel: Ember.computed('emailConfig', 'pagerdutyConfig', 'slackConfig', 'smtpConfig', 'webhookConfig', 'wechartConfig', 'wechatConfig', function () {
      var sc = Ember.get(this, 'slackConfig');
      var pc = Ember.get(this, 'pagerdutyConfig');
      var ec = Ember.get(this, 'smtpConfig');
      var wc = Ember.get(this, 'webhookConfig');
      var wcc = Ember.get(this, 'wechatConfig');

      if (sc) {
        return 'Channel';
      }

      if (pc) {
        return 'Service Key';
      }

      if (ec) {
        return 'Address';
      }

      if (wc) {
        return 'URL';
      }

      if (wcc) {
        return 'Recipient';
      }

      return 'Notifier';
    }),
    findAlerts: function findAlerts() {
      var _this = this;

      var globalStore = Ember.get(this, 'globalStore');
      var clusterId = Ember.get(this, 'clusterId');
      var clusterAlertGroups = globalStore.find('clusterAlertGroup', null, {
        filter: {
          clusterId: clusterId
        }
      });
      var projectAlertGroups = globalStore.findAll('projectAlertGroup');
      return Ember.RSVP.hash({
        clusterAlertGroups: clusterAlertGroups,
        projectAlertGroups: projectAlertGroups
      }).then(function (_ref) {
        var clusterAlertGroups = _ref.clusterAlertGroups,
            projectAlertGroups = _ref.projectAlertGroups;
        var alerts = [].concat(_toConsumableArray(clusterAlertGroups.content), _toConsumableArray(projectAlertGroups.content)).filter(function (alert) {
          var recipients = Ember.get(alert, 'recipients');

          if (!recipients || recipients.length === 0) {
            return false;
          }

          return recipients.some(function (recipient) {
            return recipient.notifierId === Ember.get(_this, 'id');
          });
        });
        return alerts;
      });
    },
    delete: function _delete() {
      var _arguments = arguments,
          _this2 = this;

      var self = this;
      var sup = self._super;
      return this.findAlerts().then(function (alerts) {
        if (alerts.length) {
          var alertNames = alerts.map(function (alert) {
            return Ember.get(alert, 'displayName');
          }).join(',');
          Ember.get(_this2, 'growl').error(Ember.get(_this2, 'intl').t('notifierPage.deleteErrorMessage', {
            displayName: Ember.get(_this2, 'displayName'),
            alertNames: alertNames
          }));
        } else {
          sup.apply(self, _arguments);
        }
      });
    },
    actions: {
      edit: function edit() {
        Ember.get(this, 'modalService').toggleModal('notifier/modal-new-edit', {
          closeWithOutsideClick: false,
          currentType: Ember.get(this, 'notifierType'),
          model: this,
          mode: 'edit'
        });
      },
      clone: function clone() {
        var nue = this.clone();
        Ember.setProperties(nue, {
          id: null,
          name: null
        });
        Ember.get(this, 'modalService').toggleModal('notifier/modal-new-edit', {
          closeWithOutsideClick: false,
          currentType: Ember.get(this, 'notifierType'),
          model: nue,
          mode: 'clone'
        });
      }
    }
  });

  _exports.default = _default;
});