define("ui/components/project-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NYouCbxx",
    "block": "{\"symbols\":[\"sortable\",\"kind\",\"p\",\"dt\"],\"statements\":[[6,[37,8],null,[[\"tableClassNames\",\"paging\",\"pagingLabel\",\"headers\",\"descending\",\"body\",\"sortBy\"],[\"bordered mt-30\",true,\"pagination.project\",[35,7],[35,6],[35,5],[35,4]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"row\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"model\",\"dt\"],[[32,3],[32,4]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"nomatch\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[10,\"td\"],[15,\"colspan\",[31,[[32,1,[\"fullColspan\"]]]]],[14,0,\"text-center text-muted lacsso pt-20 pb-20\"],[12],[1,[30,[36,0],[\"projectsPage.noMatch\"],null]],[13],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[32,2],\"norows\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[10,\"td\"],[15,\"colspan\",[31,[[32,1,[\"fullColspan\"]]]]],[14,0,\"text-center text-muted lacsso pt-20 pb-20\"],[12],[1,[30,[36,0],[\"projectsPage.noData\"],null]],[13],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2,3,4]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\",\"project-row\",\"sortBy\",\"model\",\"descending\",\"headers\",\"sortable-table\"]}",
    "meta": {
      "moduleName": "ui/components/project-table/template.hbs"
    }
  });

  _exports.default = _default;
});