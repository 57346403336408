define("ui/templates/-add-cluster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Du0JWwAU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[30,[36,0],[\"clustersPage.addPage.host.clusterName\"],null]],[1,[34,1]],[13],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"type\",\"value\"],[\"text\",[35,2,[\"user\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[1,[30,[36,0],[\"clustersPage.addPage.host.hostName\"],null]],[1,[34,1]],[13],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"type\",\"value\"],[\"text\",[35,2,[\"advertisedHostname\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"pt-10 pb-15\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[30,[36,0],[\"clustersPage.addPage.host.ssh\"],null]],[1,[34,1]],[13],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"value\",\"classNames\",\"rows\"],[[35,2,[\"ssh\"]],\"form-control no-resize\",\"6\"]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"field-required\",\"newHost\",\"input\",\"textarea\"]}",
    "meta": {
      "moduleName": "ui/templates/-add-cluster.hbs"
    }
  });

  _exports.default = _default;
});