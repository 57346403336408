define("ui/components/notifier/notifier-row/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Be4wsQ4W",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"td\"],[14,\"valign\",\"middle\"],[14,0,\"row-check\"],[14,5,\"padding-top: 2px;\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"nodeId\"],[[35,0,[\"id\"]]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,4,[\"state\"]]]]],[14,0,\"clip\"],[12],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,4,[\"name\"]]]]],[12],[2,\"\\n  \"],[1,[35,0,[\"displayName\"]]],[2,\"\\n   \"],[10,\"div\"],[14,0,\"text-muted text-small\"],[12],[1,[35,0,[\"description\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,4,[\"type\"]]]]],[14,0,\"clip\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-capitalize\"],[12],[1,[35,0,[\"notifierType\"]]],[2,\" \"],[13],[2,\"\\n\"],[6,[37,3],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"text-muted text-small\"],[12],[1,[35,0,[\"notifierTableLabel\"]]],[2,\": \"],[1,[35,0,[\"notifierValue\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[34,4,[\"created\"]]]]],[14,0,\"text-right pr-20\"],[12],[2,\"\\n  \"],[1,[35,0,[\"displayCreated\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,\"data-title\",[31,[[30,[36,7],[\"generic.actions\"],null]]]],[14,0,\"actions\"],[12],[2,\"\\n  \"],[1,[30,[36,8],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"check-box\",\"bulkActions\",\"if\",\"dt\",\"badge-state\",\"showNotifierValue\",\"t\",\"action-menu\"]}",
    "meta": {
      "moduleName": "ui/components/notifier/notifier-row/template.hbs"
    }
  });

  _exports.default = _default;
});