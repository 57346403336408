define("ui/volumes/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yGYN/V/T",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"model\",\"persistentVolumes\",\"storageClasses\",\"mode\",\"actuallySave\",\"done\",\"cancel\"],[[35,1,[\"pvc\"]],[35,1,[\"persistentVolumes\"]],[35,1,[\"storageClasses\"]],\"new\",true,[30,[36,0],[[32,0],\"done\"],null],[30,[36,0],[[32,0],\"cancel\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"cru-persistent-volume-claim\"]}",
    "meta": {
      "moduleName": "ui/volumes/new/template.hbs"
    }
  });

  _exports.default = _default;
});