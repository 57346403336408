define("ui/components/volume-source/source-local/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+Nuwztps",
    "block": "{\"symbols\":[],\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col span-6\"],[12],[2,\"\\n      \"],[10,\"label\"],[14,0,\"acc-label\"],[12],[1,[30,[36,0],[\"cruPersistentVolume.local.path.label\"],null]],[13],[2,\"\\n\"],[6,[37,4],null,[[\"editable\",\"value\"],[[35,3],[35,1,[\"path\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"type\",\"value\",\"classNames\",\"placeholder\"],[\"text\",[35,1,[\"path\"]],\"form-control\",[30,[36,0],[\"cruPersistentVolume.local.path.placeholder\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"config\",\"input\",\"editing\",\"input-or-display\"]}",
    "meta": {
      "moduleName": "ui/components/volume-source/source-local/template.hbs"
    }
  });

  _exports.default = _default;
});