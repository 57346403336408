define("ui/components/page-footer/component", ["exports", "ui/utils/constants", "ui/components/page-footer/template"], function (_exports, _constants, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    prefs: Ember.inject.service(),
    modalService: Ember.inject.service('modal'),
    layout: _template.default,
    tagName: 'footer',
    className: 'clearfix',
    projectId: Ember.computed.alias("cookies.".concat(_constants.default.COOKIE.PROJECT)),
    init: function init() {
      this._super.apply(this, arguments);

      var settings = this.get('settings');
      var cli = {};
      Object.keys(_constants.default.SETTING.CLI_URL).forEach(function (key) {
        cli[key.toLowerCase()] = settings.get(_constants.default.SETTING.CLI_URL[key]);
      });
      this.setProperties({
        cli: cli
      });
    },
    actions: {
      showAbout: function showAbout() {
        this.get('modalService').toggleModal('modal-about', {
          closeWithOutsideClick: true
        });
      },
      showWechat: function showWechat() {
        this.get('modalService').toggleModal('modal-wechat', {
          closeWithOutsideClick: true
        });
      }
    },
    displayVersion: Ember.computed('settings.rancherVersion', function () {
      var fullVersion = Ember.get(this, 'settings.rancherVersion') || Ember.get(this, 'intl').t('pageFooter.notARelease');
      var displayVersion = fullVersion;
      var match = fullVersion.match(/^(.*)-([0-9a-f]{40})-(.*)$/);

      if (match) {
        displayVersion = match[2].substr(0, 7);
      }

      return displayVersion;
    }),
    showWechat: Ember.computed('intl.locale', function () {
      var locale = this.get('intl.locale');

      if (locale) {
        return locale[0] === 'zh-hans';
      }

      return false;
    }),
    githubBase: _constants.default.EXT_REFERENCES.GITHUB,
    forumBase: _constants.default.EXT_REFERENCES.FORUM,
    cnforumBase: _constants.default.EXT_REFERENCES.CN_FORUM,
    slackBase: _constants.default.EXT_REFERENCES.SLACK
  });

  _exports.default = _default;
});