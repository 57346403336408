define("ui/components/pod-dots/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5yhBv0TJ",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,10],[[30,[36,9],[[30,[36,9],[[35,3]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,8],null,[[\"model\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center text-muted pt-20 pb-20\"],[12],[2,\"\\n\"],[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,5],[\"containersPage.table.noMatch\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[30,[36,5],[\"containersPage.table.noData\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,7],[[30,[36,11],[[35,0,[\"length\"]],[35,3,[\"length\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,4],null,[[\"content\",\"class\",\"indexTo\",\"indexFrom\",\"totalCount\",\"textLabel\"],[[35,3],\"text-center mt-10 mb-10 no-select\",[35,2],[35,1],[35,0,[\"length\"]],\"pagination.container\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"filtered\",\"indexFrom\",\"indexTo\",\"pagedContent\",\"page-numbers\",\"t\",\"searchText\",\"if\",\"container-dot\",\"-track-array\",\"each\",\"gt\"]}",
    "meta": {
      "moduleName": "ui/components/pod-dots/template.hbs"
    }
  });

  _exports.default = _default;
});