define("ui/models/nodedriver", ["exports", "@rancher/ember-api-store/models/resource", "ui/utils/constants", "ui/utils/parse-externalid"], function (_exports, _resource, _constants, _parseExternalid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.BUILT_IN_ICON_ONLY = _exports.BUILT_IN_UI = void 0;
  var BUILT_IN_UI = ['amazonec2', 'digitalocean', 'azure', 'exoscale', 'packet', 'rackspace', 'vmwarevsphere', 'aliyunecs', 'oci'];
  _exports.BUILT_IN_UI = BUILT_IN_UI;
  var BUILT_IN_ICON_ONLY = ['openstack', 'otc'];
  _exports.BUILT_IN_ICON_ONLY = BUILT_IN_ICON_ONLY;

  function displayUrl(url) {
    url = url || '';
    var parts = url.split('/');
    var out = null;

    if (parts.length < 2) {
      return url;
    }

    if (url.indexOf('github.com') >= 0) {
      out = ".../".concat(parts[parts.length - 2], "/").concat(parts[parts.length - 1]);
    } else {
      out = url;
    }

    return out;
  }

  var _default = _resource.default.extend({
    modalService: Ember.inject.service('modal'),
    catalog: Ember.inject.service(),
    intl: Ember.inject.service(),
    type: 'nodeDriver',
    catalogTemplateIcon: Ember.computed('app.baseAssets', 'externalId', function () {
      var parsedExtId = (0, _parseExternalid.parseExternalId)(Ember.get(this, 'externalId')) || null;

      if (!parsedExtId) {
        return null;
      }

      if (Ember.get(this, 'catalog').getTemplateFromCache(parsedExtId.templateId)) {
        return Ember.get(this, 'catalog').getTemplateFromCache(parsedExtId.templateId).get('links.icon');
      } else {
        return "".concat(Ember.get(this, 'app.baseAssets'), "assets/images/providers/generic-driver.svg");
      }
    }),
    displayName: Ember.computed('id', 'intl.locale', 'name', function () {
      var intl = Ember.get(this, 'intl');
      var name = Ember.get(this, 'name');
      var key = "nodeDriver.displayName.".concat(name);

      if (name && intl.exists(key)) {
        return intl.t(key);
      } else if (name) {
        return name.capitalize();
      } else {
        return "(".concat(Ember.get(this, 'id'), ")");
      }
    }),
    displayIcon: Ember.computed('hasBuiltinUi', 'name', function () {
      var name = Ember.get(this, 'name');

      if (Ember.get(this, 'hasBuiltinUi')) {
        return name;
      } else {
        return 'generic';
      }
    }),
    displayUrl: Ember.computed('url', function () {
      return displayUrl(Ember.get(this, 'url'));
    }),
    displayChecksum: Ember.computed('checksum', function () {
      return Ember.get(this, 'checksum').substring(0, 8);
    }),
    displayUiUrl: Ember.computed('uiUrl', function () {
      return displayUrl(Ember.get(this, 'uiUrl'));
    }),
    hasBuiltinUi: Ember.computed('name', function () {
      return BUILT_IN_UI.indexOf(Ember.get(this, 'name')) >= 0;
    }),
    hasBuiltinIconOnly: Ember.computed('name', function () {
      return BUILT_IN_ICON_ONLY.indexOf(Ember.get(this, 'name')) >= 0;
    }),
    isCustom: Ember.computed('builtin', 'externalId', function () {
      return !Ember.get(this, 'builtin') && !Ember.get(this, 'externalId');
    }),
    hasUi: Ember.computed('hasBuiltinUi', 'uiUrl', function () {
      return Ember.get(this, 'hasBuiltinUi') || !!Ember.get(this, 'uiUrl');
    }),
    newExternalId: Ember.computed('isSystem', 'selectedTemplateModel.id', function () {
      var externalId = _constants.default.EXTERNAL_ID.KIND_CATALOG + _constants.default.EXTERNAL_ID.KIND_SEPARATOR + Ember.get(this, 'selectedTemplateModel.id');
      return externalId;
    }),
    canEdit: Ember.computed('links.update', 'builtin', function () {
      return !!Ember.get(this, 'links.update') && !Ember.get(this, 'builtin');
    }),
    canRemove: Ember.computed('state', function () {
      return Ember.get(this, 'state') === 'inactive';
    }),
    availableActions: Ember.computed('actionLinks.{activate,deactivate}', 'state', function () {
      var a = Ember.get(this, 'actionLinks') || {};
      return [{
        label: 'action.activate',
        icon: 'icon icon-play',
        action: 'activate',
        enabled: !!a.activate && Ember.get(this, 'state') === 'inactive',
        bulkable: true
      }, {
        label: 'action.deactivate',
        icon: 'icon icon-pause',
        action: 'promotDeactivate',
        enabled: !!a.deactivate && Ember.get(this, 'state') === 'active',
        bulkable: true,
        altAction: 'deactivate'
      }];
    }),
    externalIdInfo: Ember.computed('externalId', function () {
      return (0, _parseExternalid.parseExternalId)(Ember.get(this, 'externalId'));
    }),
    actions: {
      activate: function activate() {
        return this.doAction('activate');
      },
      deactivate: function deactivate() {
        return this.doAction('deactivate');
      },
      promotDeactivate: function promotDeactivate() {
        Ember.get(this, 'modalService').toggleModal('modal-confirm-deactivate', {
          originalModel: this,
          action: 'deactivate'
        });
      },
      edit: function edit() {
        Ember.get(this, 'modalService').toggleModal('modal-edit-driver', this);
      }
    }
  });

  _exports.default = _default;
});