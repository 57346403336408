define("ui/components/input-random-port/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4UTf3q2q",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,7],null,[[\"class\",\"min\",\"max\",\"value\",\"placeholder\"],[\"form-control input-sm\",[35,6],[35,5],[35,4],[35,3]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"edit btn bg-transparent pl-0\"],[4,[38,0],[[32,0],\"showEdit\"],null],[12],[1,[30,[36,2],[[35,1]],null]],[2,\" \"],[10,\"i\"],[14,0,\"icon icon-edit\"],[12],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"standardKey\",\"t\",\"placeholder\",\"value\",\"max\",\"min\",\"input-integer\",\"showEdit\",\"if\"]}",
    "meta": {
      "moduleName": "ui/components/input-random-port/template.hbs"
    }
  });

  _exports.default = _default;
});