define("ui/components/settings/landing-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MgnHQIxr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[\"landingPage.header\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"box\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"selection\",\"value\"],[[35,1],\"ember\"]]]],[2,\"\\n      \"],[1,[30,[36,0],[\"landingPage.ember\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"selection\",\"value\"],[[35,1],\"vue\"]]]],[2,\"\\n      \"],[1,[30,[36,0],[\"landingPage.vue\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"value\",\"radio-button\"]}",
    "meta": {
      "moduleName": "ui/components/settings/landing-page/template.hbs"
    }
  });

  _exports.default = _default;
});