define("ui/catalog-tab/launch/route", ["exports", "shared/utils/util", "ui/utils/constants", "ui/utils/util"], function (_exports, _util, _constants, _util2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    modalService: Ember.inject.service('modal'),
    catalog: Ember.inject.service(),
    scope: Ember.inject.service(),
    clusterStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    growl: Ember.inject.service(),
    intl: Ember.inject.service(),
    parentRoute: 'catalog-tab',
    model: function model(params, transition) {
      var _this = this;

      var store = this.store,
          clusterStore = this.clusterStore;
      var dependencies = {
        tpl: Ember.get(this, 'catalog').fetchTemplate(params.template),
        namespaces: clusterStore.findAll('namespace')
      };

      if (params.upgrade) {
        dependencies.upgrade = Ember.get(this, 'catalog').fetchTemplate("".concat(params.template, "-").concat(params.upgrade), true);
      }

      if (params.appId) {
        dependencies.app = store.find('app', params.appId);
      }

      if (params.appName) {
        dependencies.app = store.find('app', null, {
          filter: {
            name: params.appName
          }
        }).then(function (apps) {
          return Ember.get(apps, 'firstObject');
        });
      }

      if (params.namespaceId) {
        dependencies.namespace = clusterStore.find('namespace', params.namespaceId);
      } // check to see if we navigated here naturally or page refresh


      var routeInfos = this.router._router._routerMicrolib.currentRouteInfos;

      if (routeInfos && routeInfos.findBy('name', 'apps-tab.index')) {
        // if natural get teh apps model from the already loaded route
        var appsModel = this.modelFor('apps-tab.index');
        dependencies.apps = Ember.get(appsModel, 'apps');
      } else {
        dependencies.apps = store.findAll('app');
      }

      return Ember.RSVP.hash(dependencies, 'Load dependencies').then(function (results) {
        var def = Ember.get(results, 'tpl.defaultVersion');
        var links = Ember.get(results, 'tpl.versionLinks');
        var app = Ember.get(results, 'app');
        var catalogTemplateUrl = null;
        var allApps = Ember.get(results, 'apps');

        if (app && params.appId && (!params.upgrade || params.istio)) {
          def = Ember.get(app, 'externalIdInfo.version');
        }

        if (!links[def]) {
          def = Ember.get(results, 'tpl.latestVersion');
        }

        catalogTemplateUrl = links[def];
        var version = Ember.get(_this, 'settings.rancherVersion');

        if (version) {
          catalogTemplateUrl = _util2.default.addQueryParam(catalogTemplateUrl, 'rancherVersion', version);
        }

        return _this.catalog.fetchByUrl(catalogTemplateUrl).then(function (catalogTemplate) {
          var requiredNamespace = catalogTemplate.requiredNamespace;
          var namespaceName; // if we have a required ns or we're upgrading we wont need a new namespace name

          if (requiredNamespace || params.upgrade) {
            if (requiredNamespace) {
              namespaceName = requiredNamespace;
            } else {
              namespaceName = results.namespace.name;
            }
          } else {
            namespaceName = results.tpl.get('displayName');
          }

          var existingNamespace = results.namespaces.findBy('id', namespaceName);
          var kind = 'helm';
          var neuApp = null;
          var namespace = null;
          var newAppName = null;
          var match = null;

          if (existingNamespace) {
            // find any apps that exist in the ns so we can reuse if not
            if (allApps && allApps.length > 0) {
              match = allApps.findBy('name', existingNamespace.displayName);
            }

            if (requiredNamespace || params.upgrade) {
              namespace = existingNamespace;
            } else {
              // no apps exist in the namespace that match our current ns name so we can reuse the ns
              if (match) {
                var _this$newNamespace = _this.newNamespace(existingNamespace, namespaceName);

                namespace = _this$newNamespace.namespace;
              } else {
                namespace = existingNamespace;
              }
            }

            if (match && match.name !== _constants.default.STORAGE.LONGHORN_CATALOG_ITEM_DEFAULT_NAME) {
              newAppName = _this.dedupeName(existingNamespace.displayName);
            } else {
              newAppName = existingNamespace.displayName;
            }
          } else {
            // new namespace
            var _this$newNamespace2 = _this.newNamespace(existingNamespace, namespaceName);

            namespace = _this$newNamespace2.namespace;
            newAppName = _this$newNamespace2.newAppName;
          }

          if (params.istio) {
            newAppName = '';
          }

          var verArr = Object.keys(links).filter(function (key) {
            return !!links[key];
          }).map(function (key) {
            return {
              version: key,
              sortVersion: key,
              link: links[key]
            };
          });

          if (results.app) {
            if (Ember.get(params, 'clone')) {
              neuApp = results.app.cloneForNew();
              Ember.set(neuApp, 'name', newAppName);
            } else {
              neuApp = results.app.clone();
            }
          } else {
            neuApp = _this.store.createRecord({
              type: 'app',
              name: newAppName
            });
          }

          var catalogTemplateUrlKey = def;

          if (neuApp.id) {
            var currentAppVersion = Ember.get(neuApp, 'externalIdInfo.version');
            var currentVersion = verArr.filter(function (ver) {
              return ver.version === currentAppVersion;
            });

            if (currentVersion.length === 0) {
              verArr.unshift({
                link: Ember.get(verArr, 'firstObject.link').substring(0, Ember.get(verArr, 'firstObject.link.length') - Ember.get(verArr, 'firstObject.version.length')) + currentAppVersion,
                sortVersion: currentAppVersion,
                version: "".concat(currentAppVersion, " (current)")
              });
            } else {
              currentVersion.forEach(function (ver) {
                Ember.set(ver, 'version', "".concat(ver.version, " (current)"));
              });

              if (!params.upgrade) {
                catalogTemplateUrlKey = currentAppVersion;
              }
            }
          }

          if (!params.namespaceId && params.istio) {
            namespace = null;
          }

          return Ember.Object.create({
            catalogTemplate: catalogTemplate,
            namespace: namespace,
            catalogApp: neuApp,
            catalogTemplateUrl: links[catalogTemplateUrlKey],
            // catalogTemplateUrl gets qp's added and this needs with out
            namespaces: results.namespaces,
            tpl: results.tpl,
            tplKind: kind,
            upgradeTemplate: results.upgrade,
            versionLinks: links,
            versionsArray: verArr
          });
        });
      }).catch(function (error) {
        if (error.status === 404) {
          _this.growl.fromError(_this.intl.t('newCatalog.error.appData'), error.message);
        }

        return transition.router.transitionTo('apps-tab.index');
      });
    },
    resetController: function resetController(controller, isExiting
    /* , transition*/
    ) {
      if (isExiting) {
        Ember.setProperties(controller, {
          appId: null,
          appName: null,
          catalog: null,
          clone: null,
          namespaceId: null,
          template: null,
          upgrade: null,
          istio: false
        });
      }
    },
    deactivate: function deactivate() {
      Ember.set(this, "session.".concat(_constants.default.SESSION.PROJECT_ROUTE), undefined);
    },
    actions: {
      cancel: function cancel() {
        Ember.get(this, 'modalService').toggleModal();
      }
    },
    dedupeName: function dedupeName(name) {
      var suffix = (0, _util.randomStr)(5, 5, 'novowels');
      return "".concat(name, "-").concat(suffix);
    },
    newNamespace: function newNamespace(duplicateNamespace, namespaceName) {
      var newAppName = namespaceName;

      if (duplicateNamespace) {
        newAppName = this.dedupeName(Ember.get(duplicateNamespace, 'displayName'));
      }

      var namespace = Ember.get(this, 'clusterStore').createRecord({
        type: 'namespace',
        name: newAppName,
        projectId: this.modelFor('authenticated.project').get('project.id')
      });
      return {
        namespace: namespace,
        newAppName: newAppName
      };
    }
  });

  _exports.default = _default;
});