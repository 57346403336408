define("ui/components/cru-storage-class/component", ["exports", "shared/mixins/view-new-edit", "ui/components/cru-storage-class/template", "ui/models/storageclass", "shared/mixins/child-hook", "ui/utils/constants"], function (_exports, _viewNewEdit, _template, _storageclass, _childHook, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var WAIT_FOR_FIRST_CONSUMER = 'WaitForFirstConsumer';
  var IMMEDIATE = 'Immediate';
  var LOCAL_STORAGE = 'kubernetes.io/no-provisioner';
  var LONGHORN_PROVISIONER_KEY = _constants.default.STORAGE.LONGHORN_PROVISIONER_KEY;

  var _default = Ember.Component.extend(_viewNewEdit.default, _childHook.default, {
    intl: Ember.inject.service(),
    features: Ember.inject.service(),
    layout: _template.default,
    model: null,
    longhornProvisionerKey: LONGHORN_PROVISIONER_KEY,
    titleKey: 'cruStorageClass.title',
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'wasRecycle', Ember.get(this, 'primaryResource.reclaimPolicy') === 'Recycle');
    },
    actions: {
      updateParams: function updateParams(map) {
        Ember.set(this, 'primaryResource.parameters', map);
      },
      updateOptions: function updateOptions(ary) {
        Ember.set(this, 'primaryResource.mountOptions', ary);
      }
    },
    provisionerChanged: Ember.observer('primaryResource.provisioner', function () {
      var provisioner = Ember.get(this, 'primaryResource.provisioner');

      if (this.isNew) {
        Ember.set(this, 'primaryResource.volumeBindingMode', provisioner === LOCAL_STORAGE ? WAIT_FOR_FIRST_CONSUMER : IMMEDIATE);
      }

      if (provisioner === this.longhornProvisionerKey) {
        Ember.set(this, 'primaryResource.allowVolumeExpansion', true);
      } else {
        Ember.set(this, 'primaryResource.allowVolumeExpansion', false);
      }
    }),
    paramsComponent: Ember.computed('primaryResource.provisioner', function () {
      var provisioner = Ember.get(this, 'primaryResource.provisioner');
      var entry = (0, _storageclass.getProvisioners)().findBy('value', provisioner);
      var component = 'generic';

      if (entry && entry.component) {
        component = entry.component;
      }

      return "storage-class/provisioner-".concat(component);
    }),
    provisionerChoices: Ember.computed('intl.locale', function () {
      var intl = Ember.get(this, 'intl');
      var out = (0, _storageclass.getProvisioners)().map(function (p) {
        var entry = Object.assign({}, p);
        var key = "storageClass.".concat(entry.name, ".title");

        if (intl.exists(key)) {
          entry.label = intl.t(key);
          entry.priority = 1;
        } else {
          entry.label = entry.name;
          entry.priority = 2;
        }

        return entry;
      });
      return out.sortBy('priority', 'label');
    }),
    supportedProvisionerChoices: Ember.computed('provisionerChoices', function () {
      var showUnsupported = Ember.get(this, 'features').isFeatureEnabled(_constants.default.FEATURES.UNSUPPORTED_STORAGE_DRIVERS);
      return Ember.get(this, 'provisionerChoices').filter(function (choice) {
        return showUnsupported || choice.supported;
      });
    }),
    willSave: function willSave() {
      var _arguments = arguments;
      var self = this;
      var sup = this._super;
      return this.applyHooks().then(function () {
        return sup.apply.apply(sup, [self].concat(_toConsumableArray(_arguments)));
      });
    },
    doneSaving: function doneSaving() {
      if (this.done) {
        this.done();
      }
    }
  });

  _exports.default = _default;
});