define("ui/authenticated/project/hpa/detail/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BsXlgelq",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"model\",\"deployments\",\"mode\"],[[35,0,[\"hpa\"]],[35,0,[\"deployments\"]],\"view\"]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"cru-hpa\"]}",
    "meta": {
      "moduleName": "ui/authenticated/project/hpa/detail/index/template.hbs"
    }
  });

  _exports.default = _default;
});