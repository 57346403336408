define("ui/authenticated/project/container-log/controller", ["exports", "jquery", "ui/mixins/console"], function (_exports, _jquery, _console) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_console.default, {
    bootstrap: Ember.on('init', function () {
      var body = (0, _jquery.default)('body');
      var application = (0, _jquery.default)('#application');
      body.css('overflow', 'hidden');
      application.css('padding-bottom', '0');
    })
  });

  _exports.default = _default;
});